import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Button, IconButton, TextField, Drawer, Typography, Avatar, Badge, Paper, Divider, useTheme, Tooltip,
  useMediaQuery, Fab, AppBar, Toolbar, CircularProgress, List, ListItem, ListItemAvatar, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import {
  Send as SendIcon, Chat as ChatIcon, AttachFile as AttachFileIcon, PhotoCamera as PhotoCameraIcon,
  Mic as MicIcon, ArrowBack as ArrowBackIcon, Close as CloseIcon, Stop as StopIcon, Shortcut as ShortcutIcon,
  Add as AddIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, ExpandMore as ExpandMoreIcon,
  GetApp as GetAppIcon, Delete as DeleteIcon, ArrowDownward as ArrowDownwardIcon, AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import utils from '../utils';

const Chat = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [activeChat, setActiveChat] = useState(null);

  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const mediaRecorderRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [isForwarding, setIsForwarding] = useState(false);
  const [openForwardDialog, setOpenForwardDialog] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);

  const [searchMessageTerm, setSearchMessageTerm] = useState("");
  const filteredMessages = messages.filter(message =>
    message.testo.toLowerCase().includes(searchMessageTerm.toLowerCase())
  );

  // Per il player audio e per 2x
  const [playbackRate, setPlaybackRate] = useState(1);
  const audioRef = useRef(null);

  // Funzione per il cambio dell avatar
  const handleAvatarImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Creare un oggetto Image per leggere il file
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async () => {
          // Impostare la larghezza e altezza minima
          const minSize = 300;
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calcolare il ridimensionamento in modo che la dimensione minima sia 80px
          let width = img.width;
          let height = img.height;

          if (width < minSize && height < minSize) {
            // non comprimere
          } else if (width < height) {
            height = (minSize / width) * height;
            width = minSize;
          } else {
            width = (minSize / height) * width;
            height = minSize;
          }

          // Impostare il canvas con le dimensioni calcolate
          canvas.width = width;
          canvas.height = height;

          // Disegnare l'immagine ridimensionata nel canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Comprimere l'immagine (JPEG con qualità 80) e convertirla in base64
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.8);

          // Inviare la base64 come JSON al server
          const res = await axios.post('/api/avatar', {
            avatar: compressedBase64.split(',')[1],
          }, {
            headers: {
              ...utils.getAuthHeaders(),
              'Content-Type': 'application/json',
            },
          });

          const { success, error } = res.data;
          if (success) {
            window.location.reload();
          } else {
            console.error(error);
          }
        };

        // Gestire gli errori nel caricamento dell'immagine
        img.onerror = (error) => {
          console.error('Error loading image:', error);
        };
      } catch (error) {
        console.error('Error processing avatar:', error);
      }
    }
  };

  // Gestione Chat Recenti e Contatti Espandibili
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }
    const intervalId = setInterval(() => {
      loadChats();
    }, 15000);
    return () => clearInterval(intervalId);
  }, [userId])

  const loadUser = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success) {
          setUserId(me.id);
          setUsername(me.op ? me.nome : me.ragione_sociale);
          setRole(me.op ? 'operator' : 'client');
          setTimeout(() => loadChats(), 500);
        }
      }
    } catch (_) { }
  }

  const loadChats = async () => {
    try {
      const res = await axios.get(`/api/chats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        const sortedClients = res.data.chats.sort((a, b) => {
          if (b.lastMessageAt == a.lastMessageAt) {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          }
          return Number(b.lastMessageAt) - Number(a.lastMessageAt);
        });
        setClients(sortedClients);
        const unreadCount = res.data.chats.map(c => c.unreadCount).reduce((a, b) => a + b, 0);
        setUnreadCount(unreadCount);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedMessages([]); // Reset selected messages when closing the chat
      scrollToBottom();
    }
  }, [isOpen]);

  const fetchMessages = async () => {
    try {
      const res = await axios.get('/api/chat', {
        params: { to: activeChat.id }, headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (success) {
        const messs = res.data.messaggi;
        if (messs.length) {
          const latestIndex = messageRef.current.length ? messageRef.current[messageRef.current.length - 1].index : -1;
          if (messs[messs.length - 1].index > latestIndex) {
            setMessages(messs);
            messageRef.current = messs; // Aggiorna il valore della ref
          }
        }
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let intervalId;
    if (activeChat) {
      messageRef.current = [];
      setMessages([]);
      setIsLoading(true);
      fetchMessages();
      intervalId = setInterval(fetchMessages, 3000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [activeChat]);

  const messageRef = useRef([]);
  useEffect(() => {
    messageRef.current = messages;
  }, [messages]);

  // Funz Invio Messaggi
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    if (isLoading) return;

    const payload = {
      testo: message,
      to: activeChat.id,
    };

    const formdata = new FormData();
    formdata.append("data", JSON.stringify(payload));

    setMessage("");
    setIsLoading(true); // Start loading

    try {
      const res = await axios.post('/api/chat', formdata, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        setMessages(prev => [...prev, {
          nome: username,
          utente_id: userId,
          tipo: 'text',
          testo: payload.testo,
          index: messages.length ? messages[messages.length - 1].index + 1 : 0,
          timestamp: Date.now()
        }]);
      } else {
        console.error(error);
      }
      setIsLoading(false); // End loading after successful send
    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false); // End loading on error
    }
  };

  // Funz Invio Immagini
  const handleSendImage = async (file) => {
    if (!file) return;
    if (isLoading) return;

    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      setIsLoading(true);

      const compressedFile = await imageCompression(file, options);

      const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      const base64Image = await toBase64(compressedFile);

      const payload = {
        testo: base64Image,
        to: activeChat.id,
        tipo: 'image',
      };

      const formdata = new FormData();
      formdata.append("data", JSON.stringify(payload));

      try {
        const res = await axios.post('/api/chat', formdata, {
          headers: {
            ...utils.getAuthHeaders(),
            "Content-Type": "multipart/form-data",
          },
        });
        const { success, error } = res.data;
        if (success) {
          setMessages(prev => [...prev, {
            nome: username,
            utente_id: userId,
            testo: payload.testo,
            tipo: 'image',
            index: messages.length ? messages[messages.length - 1].index + 1 : 0,
            timestamp: Date.now()
          }]);
        } else {
          console.error(error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error sending image:', error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error compressing or converting image to base64:', error);
    }
  };

  // Funz Allega File
  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (isLoading) return;

    const payload = {
      tipo: 'file',
      testo: file.name,
      to: activeChat.id,
    };

    const formdata = new FormData();
    formdata.append("data", JSON.stringify(payload));
    formdata.append("file", file);

    setMessage("");
    setIsLoading(true); // Start loading

    try {
      const res = await axios.post('/api/chat', formdata, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error, file_id } = res.data;
      if (success) {
        setMessages(prev => [...prev, {
          nome: username,
          tipo: 'file',
          utente_id: userId,
          testo: `${file_id}|${payload.testo}`,
          index: messages.length ? messages[messages.length - 1].index + 1 : 0,
          timestamp: Date.now()
        }]);
      } else {
        console.error(error);
      }
      setIsLoading(false); // End loading after successful send
    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false); // End loading on error
    }
  };

  // Funzione reg Audio
  const handleVoiceRecord = async () => {
    if (isRecording) {
      mediaRecorderRef.current?.stop();
      setIsRecording(false);
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });

        // Funzione per convertire il Blob in Base64
        const toBase64 = (blob) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        try {
          const base64Audio = await toBase64(audioBlob);

          setIsLoading(true); // Inizia il caricamento

          const payload = {
            testo: base64Audio,
            to: activeChat.id,
            tipo: 'audio',
          };

          const formdata = new FormData();
          formdata.append("data", JSON.stringify(payload));

          // Invia l'audio codificato in Base64 al backend
          try {
            const res = await axios.post('/api/chat', formdata, {
              headers: {
                ...utils.getAuthHeaders(),
                "Content-Type": "multipart/form-data",
              },
            });
            const { success, error } = res.data;
            if (success) {
              setMessages(prev => [...prev, {
                nome: username,
                utente_id: userId,
                testo: payload.testo,
                tipo: 'audio',
                index: messages.length ? messages[messages.length - 1].index + 1 : 0,
                timestamp: Date.now()
              }]);
            } else {
              console.error(error);
            }
            setIsLoading(false); // Termina il caricamento dopo l'invio
          } catch (error) {
            console.error('Error sending audio:', error);
            setIsLoading(false); // Termina il caricamento in caso di errore
          }
        } catch (error) {
          console.error('Error converting audio to base64:', error);
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error recording audio:', error);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  // Funzione Elimina Messaggi
  const handleDeleteMessageConfirm = async () => {
    try {
      const res = await axios.post('/api/chatdelete', { to: activeChat.id, index: messageToDelete.index }, {
        headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (success) {
        setMessages(prevMessages => {
          const newMessages = [];
          for (const message of prevMessages) {
            if (message.index != messageToDelete.index) {
              newMessages.push(message);
            } else {
              newMessages.push({
                ...message,
                testo: 'Messaggio Eliminato',
                tipo: 'text',
              });
            }
          }
          return newMessages;
        })
        setMessageToDelete(null);
        setOpenDeleteDialog(false);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Funzione per aprire l'anteprima del file nel browser
  const handlePreview = async (fileId) => {
    try {
      const response = await axios.get(`/api/scarica?id=${fileId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const fileBlob = new Blob([response.data], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(fileBlob);
      window.open(fileUrl, "_blank");

    } catch (error) {
      console.error('Errore durante l\'anteprima del file:', error.response || error.message);
      alert('Errore nell\'anteprima del file. Riprova più tardi.');
    }
  };

  // Funzione per i Bubble dei Messaggi
  const MessageBubble = ({ message }) => {
    const isOwn = message.utente_id === userId;
    const isSelected = selectedMessages.includes(message.index);
    const isOperatorMessage = role === 'operator' && message.utente_id !== activeChat?.id;

    const toggleSelectMessage = () => {
      setSelectedMessages(prev =>
        prev.includes(message.index)
          ? prev.filter(id => id !== message.index)
          : [...prev, message.index]
      );
    };

    // Funzione Elimina Messaggi con Dialog
    const handleDeleteMessage = () => {
      setMessageToDelete(message);
      setOpenDeleteDialog(true);
    };

    if (role !== 'operator' && role !== 'client') {
      return null;
    }

    // Colori Nomi Bubble
    const senderColor = isOwn ? '#1f618d' : '#ADD8E6';

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: isOperatorMessage ? 'flex-end' : isOwn ? 'flex-end' : 'flex-start',
          mb: 2,
          alignItems: 'center', // Align icons with message
        }}
        onClick={toggleSelectMessage} // Toggle selection on click
      >
        {!isOwn && (
          <Avatar
            sx={{ mr: 1, width: 30, height: 30 }}
            src={role === 'client' ? './avatar.png' : `/api/avatar?id=${message.utente_id}`}
          />
        )}

        {/* Bubble Message della chat */}
        <Paper
          elevation={1}
          sx={{
            maxWidth: '70%',
            p: 1,
            px: 2, // Mantieni il padding orizzontale
            py: 1, // Riduci il padding verticale
            backgroundColor: isOwn ? '#6dadff' : '#4076bf', // Colore mittente lightblue e destinatario DarkCyan
            color: isOwn ? 'white' : 'white', // Colore del testo
            borderRadius: 5,
            position: 'relative',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
          }}
        >

          {role === 'operator' && activeChat?.name !== 'Studio Romeo' && (
            <Typography
              variant="caption"
              sx={{
                fontWeight: 'bold',
                color: senderColor,
                opacity: 0.7,
                alignSelf: 'flex-start',
              }}
            >
              {message.nome}
            </Typography>
          )}

          {/* Invio Messaggi tipo testo */}
          {message.tipo === 'text' && (
            <pre
              style={{
                whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: 'system-ui',
                fontSize: '15px', marginTop: '0px', marginBottom: '0px',
                fontStyle: message.testo == 'Messaggio Eliminato' ? 'italic' : 'inherit'
              }}>
              {message.testo}
            </pre>)}

          {/* Invio Immagini e Fotocamera con Download */}
          {message.tipo === 'image' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img
                src={`data:image/jpeg;base64,${message.testo}`}
                alt="Immagine inviata"
                style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px' }} // Stile opzionale
              />
            </Box>
          )}

          {/* Invio Audio con Download */}
          {message.tipo === 'audio' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
              {/* Player audio con riferimento */}
              <audio
                ref={audioRef}
                controls
                style={{ flexGrow: 1 }}
                onPlay={() => {
                  if (audioRef.current) {
                    audioRef.current.playbackRate = playbackRate; // Imposta la velocità attuale
                  }
                }}
              >
                <source src={`data:audio/wav;base64,${message.testo}`} type="audio/wav" />
              </audio>

              {/* Pulsante cliccabile per cambiare velocità */}
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  const rates = [0.5, 1, 1.5, 2];
                  const currentIndex = rates.indexOf(playbackRate);
                  const newRate = rates[(currentIndex + 1) % rates.length];
                  setPlaybackRate(newRate);
                  if (audioRef.current) {
                    audioRef.current.playbackRate = newRate;
                  }
                }}
                sx={{
                  backgroundColor: '#6495ED',
                  color: 'white',
                  '&:hover': { backgroundColor: '#4169E1' },
                  borderRadius: '50%',
                  minWidth: 'auto',
                  padding: '1px',
                  fontSize: '10px',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {`${playbackRate}x`}
              </Button>
            </Box>
          )}

          {/* Allega file con Preview e Download */}
          {message.tipo === 'file' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AttachFileIcon fontSize="small" />
              {message.testo.split('|')[1].toLowerCase().endsWith('.pdf') ?
                <Typography
                  onClick={() => {
                    role === 'client'
                      ? utils.cliOpenPdfInNewTab(message.testo.split('|')[0])
                      : utils.opOpenPdfInNewTab(message.testo.split('|')[0]);
                  }}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {message.testo.split('|')[1]} {/* Nome del file visibile */}
                </Typography> : <Typography>{message.testo.split('|')[1]}</Typography>
              }
            </Box>
          )}

          {/* Ora Messaggi */}
          <Typography variant="caption" sx={{ display: 'block', mt: 0.3, opacity: 0.7, fontFamily: 'system-ui', fontSize: '11px' }}>
            {new Date(Number(message.timestamp)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </Typography>

          {isSelected && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: isOperatorMessage ? '100%' : isOwn ? '100%' : '-35px',
                display: 'flex',
                flexDirection: 'column', // Dispone le icone una sotto l'altra
                alignItems: 'center',
                gap: '0px', // Distanza verticale tra le icone
              }}
            >
              {/* Icona Inoltro Messaggi */}
              {role === 'operator' && (
                <IconButton
                  color="primary"
                  size="small"
                  disabled="true"
                  onClick={() => setOpenForwardDialog(true)}
                >
                  <ShortcutIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              )}

              {/* Icona Download */}
              {message.tipo === 'file' && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    role === 'client'
                      ? utils.cliDownload(message.testo.split('|')[0])
                      : utils.opDownload(message.testo.split('|')[0]);
                  }}
                >
                  <GetAppIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              )}

              {/* Icona Elimina Messaggi */}
              {role === 'operator' && isOwn && message.testo != 'Messaggio Eliminato' && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleDeleteMessage} // Open the delete dialog
                >
                  <DeleteIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              )}
              {/* Icona Download Immagine */}
              {message.tipo === 'image' && (
                <IconButton
                  color="primary"
                  size="small"
                  href={`data:image/jpeg;base64,${message.testo}`}
                  download="immagine.jpg"
                >
                  <GetAppIcon sx={{ fontSize: '18px' }} />
                </IconButton>
              )}
            </Box>
          )}
        </Paper>
      </Box>
    );
  };

  // Funzione Per Inoltrare i Messaggi
  const handleForwardMessages = async (targetChat) => {
    const forwardedMessages = selectedMessages.map(id => {
      const message = messages.find(msg => msg.index === id);
      return {
        ...message,
        index: Date.now(), // Assign new unique index for forwarded message
        timestamp: new Date().toISOString(),
      };
    });

    try {
      const payload = {
        messages: forwardedMessages,
        to: targetChat.id,
      };

      const formdata = new FormData();
      formdata.append("data", JSON.stringify(payload));

      const res = await axios.post('/api/forward', formdata, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });

      const { success, error } = res.data;
      if (success) {
        setMessages(prev => [...prev, ...forwardedMessages]);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error('Error forwarding messages:', error);
    }

    setSelectedMessages([]);
    setIsForwarding(false);
    setOpenForwardDialog(false);
  };

  // Funzione Apertura Chat
  const handleOpenChat = async (cl) => {
    setActiveChat(cl);
    setClients(prevClients =>
      prevClients.map(c =>
        c.id === cl.id ? { ...c, unreadCount: 0 } : c
      )
    );
    const unreadCount = clients.map(c => c.id === cl.id ? 0 : c.unreadCount).reduce((a, b) => a + b, 0);
    setUnreadCount(unreadCount);
    try {
      const res = await axios.post('/api/chatletta', { to: cl.id }, {
        headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (!success) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const openChat = () => {
    if (role == 'client') {
      if (clients[0]) {
        setIsOpen(true);
        handleOpenChat(clients[0]);
      }
    } else {
      setIsOpen(true);
    }
  }

  const closeChat = () => {
    setIsOpen(false);
    setActiveChat(null);
  }

  // Esapande i recenti all apertura della chat se ci sono notifiche
  useEffect(() => {
    if (unreadCount > 0 && role === 'operator') {
      setExpanded('recentChats');
    }
  }, [unreadCount, role]);

  // Mostra numero messaggi su scheda browser
  useEffect(() => {
    if (unreadCount > 0) {
      document.title = `🔔 (${unreadCount}) Studio Romeo`;
    } else {
      document.title = 'Studio Romeo';
    }
  }, [unreadCount]);  

  const filteredClients = clients.filter(a => a.name.toLowerCase()
    .replace(/[^\p{L}\p{N}]/gu, '')
    .includes(searchTerm.toLowerCase().replace(/[^\p{L}\p{N}]/gu, '')));

  // Chat Recenti e Contatti Espandibili
  const recentChats = filteredClients.filter(client => client.lastMessageAt > Date.now() - 7 * 24 * 60 * 60 * 1000); // Chat degli ultimi 7 giorni
  const otherContacts = filteredClients.filter(client => client.lastMessageAt <= Date.now() - 7 * 24 * 60 * 60 * 1000); // Altri contatti
  const otherContactsOp = otherContacts.filter(client => client.op);
  const otherContactsCli = otherContacts.filter(client => !client.op);

  if (!userId) {
    return null;
  }

  // Dot chat da gestire e gestita
  const HandleDot = ({ client, clients, setClients }) => {
    const handleDotClick = async (e) => {
      e.stopPropagation();
      try {
        const res = await axios.post('/api/update-to-handle', {
          clientId: client.id,
          toHandle: !client.toHandle
        }, {
          headers: utils.getAuthHeaders()
        });

        if (res.data.success) {
          setClients(clients.map(c =>
            c.id === client.id ? { ...c, toHandle: !c.toHandle } : c
          ));
        }
      } catch (error) {
        console.error('Error updating toHandle flag:', error);
      }
    };

    return (
      <Tooltip title={client.toHandle ? "Da gestire" : "Non gestito"}>
        <Box
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={handleDotClick}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: client.toHandle ? 'orange' : 'lightgray',
              border: '0px solid grey', // Optional: Add a border for better visibility
            }}
          />
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      {/* Icona della Chat Cliccabile per aprirla */}
      <Fab
        color="primary"
        size="small"
        onClick={() => openChat()}
        sx={{
          position: 'fixed',
          bottom: 65,
          right: 10,
        }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <ChatIcon />
        </Badge>
      </Fab>

      {/* Corpo Chat */}
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={isOpen}
        onClose={() => closeChat()}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : isExpanded ? '100%' : 700, // Wider on desktop
            height: isMobile ? '100%' : '100vh',
            borderRadius: isMobile ? 0 : 4, // Rounded corners only on desktop
            // scrollbarColor: 'transparent transparent'
          }
        }}
      >
        {/* Barra di stato alta della chat */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <AppBar position="static" color="primary">
            <Toolbar>
              {(role != 'client' || null) && activeChat && (
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setActiveChat(null)}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 0, flexGrow: 1 }}>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  {/* Avatar della chat attiva */}
                  <Avatar sx={{ mr: 2, width: 40, height: 40 }}
                    src={role === 'client' ? './avatar.png' : (activeChat ? `/api/avatar?id=${activeChat.id}` : `/api/avatar?id=${userId}`)} />
                  {role === 'operator' && !activeChat && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 10,
                        backgroundColor: 'white',
                        borderRadius: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        id="avatar-upload"
                        style={{ display: 'none' }}
                        onChange={handleAvatarImageChange}
                      />
                      <label htmlFor="avatar-upload" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <AddIcon sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer' }} />
                      </label>
                    </Box>
                  )}
                </Box>
                <Typography variant="h6">
                  {activeChat ? activeChat.name : username}
                </Typography>
              </Box>

              {activeChat && (
                <TextField
                  placeholder="Cerca"
                  variant="standard"
                  value={searchMessageTerm}
                  onChange={(e) => setSearchMessageTerm(e.target.value)}
                  size="small"
                  sx={{
                    ml: 1,
                    width: { xs: '100%', sm: '300px' }, // Dimensione fissa su desktop e full width su mobile
                    flexGrow: { xs: 1, sm: 0 }, // Flex grow solo su mobile
                  }}
                  InputProps={{
                    sx: {
                      border: 'none',
                      '& .MuiOutlinedInput-root': {
                        border: 'none',
                        borderRadius: '4px',
                      },
                    },
                  }}
                />
              )}

              {role === 'client' && (
                <Tooltip title="Carica la tua immagine profilo">
                  <Box sx={{ position: 'relative', display: 'inline-flex', ml: 0, mr: 0 }}>
                    <Avatar sx={{ width: 30, height: 30 }} src={`/api/avatar?id=${userId}`} />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: -5,
                        backgroundColor: 'white',
                        borderRadius: '100%',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        id="avatar-upload-client"
                        style={{ display: 'none' }}
                        onChange={handleAvatarImageChange}
                      />
                      <label htmlFor="avatar-upload-client" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <AddIcon sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer' }} />
                      </label>
                    </Box>
                  </Box>
                </Tooltip>
              )}

              <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <IconButton color="inherit" onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </Box>
              <IconButton color="inherit" onClick={() => closeChat()}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {/* Only the contact list will have the search box */}
          {activeChat === null ? (
            <Box sx={{ p: 2, borderRadius: 1, bgcolor: theme.palette.grey[100] }}>
              {role === 'operator' && <TextField
                placeholder="Cerca contatti"
                variant="outlined"
                onChange={handleSearch}
                fullWidth
                InputProps={{
                  sx: {
                    border: 'none', // Remove borders
                    '& .MuiOutlinedInput-root': {
                      border: 'none', // Remove input borders
                      borderRadius: '4px', // Keep rounded corners
                    },
                  },
                }}
              />}
              {role === 'operator' && <Divider sx={{ my: 1 }} />}
              <List sx={{ overflow: 'auto', flexGrow: 1 }}>

                {/* Chat Recenti */}
                <Accordion expanded={expanded === 'recentChats'} onChange={handleChange('recentChats')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold' }}>Recenti</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {recentChats.map((client) => (
                        <ListItem
                          key={client.id}
                          onClick={() => handleOpenChat(client)}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                            }
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar sx={{ width: 40, height: 40 }} src={`/api/avatar?id=${client.id}`} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {client.name}
                                {client.unreadCount > 0 && (
                                  <Badge
                                    badgeContent={client.unreadCount}
                                    color="error"
                                    sx={{ ml: 2 }} // Add spacing between name and badge
                                  >
                                    <span></span>
                                  </Badge>
                                )}
                              </Box>
                            }
                            secondary={<Box
                              component="span"
                              sx={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {client.lastMessage}
                            </Box>}
                          />
                          {/*{role === 'operator' && <HandleDot client={client} clients={clients} setClients={setClients} />}*/}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>

                {/* Contatti Operatori */}
                <Accordion expanded={expanded === 'otherContactsOp'} onChange={handleChange('otherContactsOp')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold' }}>Operatori</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {otherContactsOp.map((client) => (
                        <ListItem
                          key={client.id}
                          onClick={() => handleOpenChat(client)}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                            }
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar sx={{ width: 40, height: 40 }} src={`/api/avatar?id=${client.id}`} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {client.name}
                                {client.unreadCount > 0 && (
                                  <Badge
                                    badgeContent={client.unreadCount}
                                    color="error"
                                    sx={{ ml: 2 }} // Add spacing between name and badge
                                  >
                                    <span></span>
                                  </Badge>
                                )}
                              </Box>
                            }
                            secondary={<Box
                              component="span"
                              sx={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {client.lastMessage}
                            </Box>}
                          />
                          {/*{role === 'operator' && <HandleDot client={client} clients={clients} setClients={setClients} />}*/}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>

                {/* Contatti Clienti */}
                <Accordion expanded={expanded === 'otherContactsCli'} onChange={handleChange('otherContactsCli')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold' }}>Clienti</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {otherContactsCli.map((client) => (
                        <ListItem
                          key={client.id}
                          onClick={() => handleOpenChat(client)}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                            }
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar sx={{ width: 40, height: 40 }} src={`/api/avatar?id=${client.id}`} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {client.name}
                                {client.unreadCount > 0 && (
                                  <Badge
                                    badgeContent={client.unreadCount}
                                    color="error"
                                    sx={{ ml: 2 }} // Add spacing between name and badge
                                  >
                                    <span></span>
                                  </Badge>
                                )}
                              </Box>
                            }
                            secondary={<Box
                              component="span"
                              sx={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {client.lastMessage}
                            </Box>}
                          />
                          {/*{role === 'operator' && <HandleDot client={client} clients={clients} setClients={setClients} />}*/}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>

                {/* per ora omettiamo */}
                {/* {role === 'operator' && (
                  <ListItem
                    onClick={() => setActiveChat('operators')}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>O</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Studio Romeo"
                      secondary="Gruppo Studio"
                    />
                  </ListItem>
                )} */}

                {/* Parte per i Contatti trovati */}
                {role === 'operator' && filteredClients.length == 0 &&
                  <Typography variant="body2" sx={{ textAlign: 'center', color: theme.palette.text.secondary }}>
                    Nessun contatto trovato
                  </Typography>}
              </List>
            </Box>
          ) : (
            <>

              {/* Corpo della chat tra barra e campo invio messaggi*/}
              <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2, backgroundColor: theme.palette.grey[50], }}>
                {filteredMessages.map((msg) => (
                  <MessageBubble key={msg.index} message={msg} />
                ))}
                <div ref={messagesEndRef} />
                <IconButton
                  sx={{
                    position: 'absolute',
                    bottom: 65,
                    right: isMobile ? 0 : 10,
                  }}
                  onClick={scrollToBottom}
                >
                  <ArrowDownwardIcon sx={{ fontSize: '24px', color: 'grey.500' }} />
                </IconButton>
              </Box>

              {/* Paper Invio Messaggi Audio File ecc */}
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <Box sx={{ display: 'flex', gap: 0, alignItems: 'center' }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  <IconButton
                    color="primary"
                    disabled={isLoading}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <AttachFileIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    disabled={isLoading}
                    onClick={() => document.getElementById('image-upload').click()}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        handleSendImage(e.target.files[0]);
                      }
                    }}
                  />

                  {/* Campo dove scrivi il messaggio */}
                  <TextField
                    fullWidth
                    size="small"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Scrivi..."
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.shiftKey) {// Vai a capo con Shift + Enter
                        e.preventDefault();
                        setTimeout(() => {
                          setMessage((prevMessage) => prevMessage + '\n');
                        }, 50);
                      } else if (e.key === 'Enter') { // Invio messaggio con Enter
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                    variant="outlined"
                    multiline
                    minRows={1}
                    maxRows={4}
                    InputProps={{
                      style: {
                        overflow: 'hidden',
                        overflowWrap: 'break-word',
                        resize: 'none',
                        borderRadius: '16px',
                      },
                    }}
                  />

                  <IconButton
                    color={isRecording ? 'error' : 'primary'}
                    disabled={isLoading}
                    onClick={handleVoiceRecord}
                  >
                    {isRecording ? <StopIcon /> : <MicIcon />}
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!message.trim()}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                  </IconButton>
                </Box>
              </Paper>
            </>
          )}
        </Box>

        {/* Dialog Inoltro Messaggi */}
        <Dialog
          open={openForwardDialog}
          onClose={() => setOpenForwardDialog(false)}
        >
          <DialogTitle>Inoltra Messaggi</DialogTitle>
          <DialogContent>
            <List>
              {clients.filter(client => client.op).map(client => (
                <ListItem
                  key={client.id}
                  button
                  onClick={() => {
                    handleForwardMessages(client); // Forward messages to selected contact
                    setOpenForwardDialog(false); // Close dialog after forwarding
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ width: 40, height: 40 }} src={`/api/avatar?id=${client.id}`} />
                  </ListItemAvatar>
                  <ListItemText primary={client.name} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenForwardDialog(false)} color="secondary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog Conferma Elimina Messaggi */}
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Conferma Eliminazione</DialogTitle>
          <DialogContent>
            <Typography>
              Sei sicuro di voler eliminare questo messaggio?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
              Annulla
            </Button>
            <Button
              onClick={() => handleDeleteMessageConfirm()}
              color="primary"
            >
              Elimina
            </Button>
          </DialogActions>
        </Dialog>

      </Drawer>
    </>
  );
};

export default Chat;
