import React, { useState, useEffect } from 'react';
import {
  Container, Paper, Grid, Typography, Box, Chip, TextField,
  Autocomplete, Select, MenuItem, InputLabel, FormControl,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  LinearProgress, Link, Snackbar, Alert, CircularProgress, Button, IconButton
} from '@mui/material';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { styled } from '@mui/material/styles';
import { Visibility as VisibilityIcon, Download as DownloadIcon } from '@mui/icons-material';
import axios from 'axios';
import utils from '../../utils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  marginTop: theme.spacing(1),
  boxShadow: theme.shadows[3],
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StatusDot = styled(Box)(({ color }) => ({
  width: 12,
  height: 12,
  backgroundColor: color,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: 8,
}));

const ClienteDashboard = () => {
  const [statoPagamento, setStatoPagamento] = useState('Tutti (non pagati)');
  const primoAnno = 2020;
  const annoCorrente = new Date().getFullYear();
  const anni = ['Tutti gli anni']; // Aggiungi "Tutti gli anni"
  for (let a = primoAnno; a <= annoCorrente + 1; a++) {
    anni.push(a);
  }
  const [annoSelezionato, setAnnoSelezionato] = useState('Tutti gli anni'); // Imposta default a "Tutti gli anni"
  const [parcelle, setParcelle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadUser();
    loadData(annoSelezionato);
  }, [annoSelezionato]);

  const loadUser = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success) {
          if (!me.isCliAdmin) {
            const email = me.email.replace('@', '%40');
            window.location.href = `/login?error=Inserire%20la%20password%20di%20amministrazione&email=${email}&backto=%2Fcli%2Famministrazione`;
          }
          return;
        }
      }
    } catch (e) {
      console.error(e)
    }
    if (process.env.REACT_APP_ENV !== "test") {
      //window.location.href = "/login?backto=%2Fcli%2Famministrazione";
    }
  }

  const loadData = async (year) => {
    setLoading(true);
    try {
      let res;
      if (year === 'Tutti gli anni') {
        // Fai una richiesta per tutti gli anni
        res = await axios.get(`/api/avvisiparcellacli`, {
          headers: utils.getAuthHeaders(),
        });
      } else {
        // Fai una richiesta solo per l'anno selezionato
        res = await axios.get(`/api/avvisiparcellacli?y=${year}`, {
          headers: utils.getAuthHeaders(),
        });
      }

      const { success, error } = res.data;
      if (success) {
        setParcelle(res.data.avvisi.map(a => {
          return {
            ...a,
            stato: getStato(a),
            importo: (a.tot_a_pagare / 100).toFixed(2),
            data: utils.revDate(a.data_documento.substring(0, 10))
          }
        }));
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handlePayment = async (id) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `/api/checkout`,
        { id },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.href = res.data.url;
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const getStato = avviso => {
    if (avviso.pagato) {
      return 'Pagato';
    }
    if (Date.now() - avviso.confermato_at <= 15 * 24 * 60 * 60 * 1000) {
      return 'Da Pagare';
    }
    if (Date.now() - avviso.confermato_at <= 30 * 24 * 60 * 60 * 1000) {
      return 'In Scadenza';
    }
    return 'Scaduto';
  }

  const getPieChartData = () => {
    return [
      // { name: 'Pagato', value: parcelle.filter(p => p.stato === 'Pagato').reduce((sum, p) => sum + Number(p.importo), 0), color: '#4caf50' },
      { name: 'Meno di 15 giorni alla scadenza', value: Number(parcelle.filter(p => p.stato === 'In Scadenza').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)), color: '#ff9800' },
      { name: 'Da Pagare', value: Number(parcelle.filter(p => p.stato === 'Da Pagare').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)), color: '#ffd700' },
      { name: 'Scaduto, oltre 30 giorni dall invio', value: Number(parcelle.filter(p => p.stato === 'Scaduto').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)), color: '#f44336' }
    ];
  };

  const getPaymentCompletionPercentage = () => {
    const total = parcelle.reduce((sum, p) => sum + Number(p.importo), 0);
    const paid = parcelle.filter(p => p.stato === 'Pagato').reduce((sum, p) => sum + Number(p.importo), 0);
    return total === 0 ? 0 : (paid / total) * 100;
  };

  const handleStatoPagamentoChange = (event) => {
    setStatoPagamento(event.target.value);
  };

  const parcelleFiltrate = parcelle.filter(parcella =>
    (statoPagamento === 'Tutti (non pagati)' && parcella.stato !== 'Pagato') || parcella.stato === statoPagamento
  );

  const getDotColor = (stato) => {
    switch (stato) {
      case 'Pagato': return '#4caf50'; // Green
      case 'Da Pagare': return '#ffd700'; // Yellow
      case 'In Scadenza': return '#ff9800'; // Orange
      case 'Scaduto': return '#f44336'; // Red
      default: return '#ccc'; // Gray
    }
  };

  // Funzione per scaricare un documento dato l'ID
  const handleDownload = async (avvisoId) => {
    try {
      const response = await axios.get(`/api/avvisopdf?id=${avvisoId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error(error);
    }
  };

  // Funzione per visualizzare un documento in anteprima
  const previewDoc = async (docId) => {
    setError(null);
    try {
      const response = await axios.get(`/api/avvisopdf?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const fileBlob = new Blob([response.data], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(fileBlob);

      window.open(fileUrl, '_blank');
    } catch (error) {
      setError("File non trovato.");
    }
  };

  // Funzione per Pagare tutti gli avvisi
  const handlePayAll = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `/api/checkoutfull`, {},
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.href = res.data.url;
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Container>
      <StyledPaper>
        <Typography variant="h4" gutterBottom>
          Contabilità
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Anno</InputLabel>
              <Select value={annoSelezionato} onChange={(e) => setAnnoSelezionato(e.target.value)} label="Anno">
                {anni.map((anno, index) => (
                  <MenuItem key={index} value={anno}>
                    {anno}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Stato Pagamento</InputLabel>
              <Select value={statoPagamento} onChange={handleStatoPagamentoChange} label="Stato Pagamento">
                <MenuItem value="Tutti (non pagati)"><em>Tutti (non pagati)</em></MenuItem>
                {/* <MenuItem value="Pagato">Pagato</MenuItem> */}
                <MenuItem value="Da Pagare">Da Pagare</MenuItem>
                <MenuItem value="In Scadenza">In Scadenza</MenuItem>
                <MenuItem value="Scaduto">Scaduto</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {loading ? <CircularProgress sx={{ display: 'block', margin: '0 auto' }} /> :
          <Grid container spacing={5}>
            <Grid item xs={12} lg={6} sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom>
                Stato dei Pagamenti
              </Typography>
              <Box sx={{ marginBottom: 3 }}>
                {/* <StyledChip label="Pagato" sx={{ backgroundColor: '#4caf50', color: 'white' }} /> */}
                <StyledChip label="Da Pagare" sx={{ backgroundColor: '#ffd700', color: 'black' }} />
                <StyledChip label="In Scadenza" sx={{ backgroundColor: '#ff9800', color: 'white' }} />
                <StyledChip label="Scaduto" sx={{ backgroundColor: '#f44336', color: 'white' }} />
              </Box>

              <Box sx={{ marginBottom: 3 }}>
                {/* <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <StatusDot color="#4caf50" />
                  Pagato: €{parcelle.filter(p => p.stato === 'Pagato').reduce((sum, p) => sum + Number(p.importo), 0).toFixed(2)}
                </Typography> */}
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <StatusDot color="#ffd700" />
                  Da Pagare: €{parcelle.filter(p => p.stato === 'Da Pagare').reduce((sum, p) => sum + Number(p.importo), 0).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Typography>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <StatusDot color="#ff9800" />
                  In Scadenza: €{parcelle.filter(p => p.stato === 'In Scadenza').reduce((sum, p) => sum + Number(p.importo), 0).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Typography>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                  <StatusDot color="#f44336" />
                  Scaduto: €{parcelle.filter(p => p.stato === 'Scaduto').reduce((sum, p) => sum + Number(p.importo), 0).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Pagamenti Completati
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={getPaymentCompletionPercentage()}
                  sx={{ height: 10, borderRadius: 5 }}
                />
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  {getPaymentCompletionPercentage().toFixed(2)}%
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ResponsiveContainer width="100%" height={330}>
                  <PieChart>
                    <Pie
                      data={getPieChartData()}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={20}
                      outerRadius={100}
                      paddingAngle={5}
                      cornerRadius={5}
                      fill="#8884d8"
                      label={({ value }) => `€${value.toLocaleString('it-IT', { minimumFractionDigits: 2 })}`}
                    >
                      {getPieChartData().map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => `€${value.toLocaleString('it-IT', { minimumFractionDigits: 2 })}`} />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
          </Grid>
        }
      </StyledPaper>
      {error && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Dettagli Parcelle
        </Typography>
        {parcelleFiltrate.length > 0 && (
          <Button
            variant="contained"
            color="success"
            onClick={handlePayAll}
            sx={{
              backgroundColor: 'red',
              color: 'white',
              marginBottom: 1,
              animation: 'blink-animation 2s infinite',
              '@keyframes blink-animation': {
                '50%': {
                  opacity: 0.2,
                },
              },
            }}
          >
            Paga Tutti
          </Button>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id Parcella</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Importo</TableCell>
                <TableCell>Stato</TableCell>
                <TableCell>Documento</TableCell>
                <TableCell>Pagamento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parcelleFiltrate.map((parcella) => (
                <TableRow key={parcella.id}>
                  <TableCell>
                    {/* <Link href={`/parcella/${parcella.id}`} underline="hover"> */}
                    {parcella.id}
                    {/* </Link> */}
                  </TableCell>
                  <TableCell>{parcella.data}</TableCell>
                  <TableCell>€{Number(parcella.importo).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell>
                    <StatusDot color={getDotColor(parcella.stato)} />
                    {parcella.stato}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDownload(parcella.id)}
                      disabled={parcella.esterno && !parcella.doc_esterno}
                      sx={{ color: 'primary.main' }}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => previewDoc(parcella.id)}
                      disabled={parcella.esterno && !parcella.doc_esterno}
                      sx={{ color: 'primary.main' }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handlePayment(parcella.id)}
                      sx={{
                        backgroundColor: 'red',
                        color: 'white',
                        animation: 'blink-animation 2s infinite',
                        '@keyframes blink-animation': {
                          '50%': {
                            opacity: 0.2,
                          },
                        },
                      }}
                    >
                      Paga
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>
    </Container>
  );
};

export default ClienteDashboard;
