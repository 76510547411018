import React, { useState, useEffect } from "react";
import {
  Paper, Typography, Container, CircularProgress, Grid, TextField, Button, Link, Divider, Box, Tabs, Tab,
  Tooltip, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Snackbar, InputAdornment,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import {
  Add as AddIcon, Remove as RemoveIcon, Upload as UploadIcon, Delete as DeleteIcon, Visibility as VisibilityIcon,
  Download as DownloadIcon, Close as CloseIcon
} from "@mui/icons-material";

const DettagliAzienda = () => {
  const [searchParams] = useSearchParams();
  const [azienda, setAzienda] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAzienda, setEditedAzienda] = useState({});
  const [etichette, setEtichette] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [aziendaFiles, setAziendaFiles] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState('');
  const [note, setNote] = useState('');

  const [sortField, setSortField] = useState('');
  const [sortAsc, setSortAsc] = useState(true);
  const [fileSearchTerm, setFileSearchTerm] = useState('');
  const [allAziendaFiles, setAllAziendaFiles] = useState([]);

  const id = searchParams.get("id");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          try {
            const res = await axios.get(`/api/getuser?id=${id}`, {
              headers: utils.getAuthHeaders(),
            });
            const { success } = res.data;
            if (success) {
              setAzienda(res.data.user);
              setEditedAzienda(res.data.user);
              setEtichette(res.data.user.etichette.split(',').filter(e => e.length));
              setAllAziendaFiles(res.data.user.files || []);
              setAziendaFiles(res.data.user.files || []);
              setPaymentStatus(res.data.user.stato_pagamenti || '');
            }
          } catch (error) {
            console.log(error);
            setSnackbarMessage("Si è verificato un errore imprevisto sul nostro server.");
            setSnackbarOpen(true);
          }
          setIsLoading(false);
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setSnackbarMessage("Si è verificato un errore imprevisto sul nostro server.");
      setSnackbarOpen(true);
    }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
    setIsLoading(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAzienda({ ...editedAzienda, [name]: value });
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote(newNote);
    setEditedAzienda(prevState => ({ ...prevState, note: newNote }));
  };

  // Funz per la conferma delle mod
  const handleConfirmClick = async () => {
    try {
      const res = await axios.post(`/api/updateutente`, { ...editedAzienda, id, etichette, paymentStatus }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Si è verificato un errore imprevisto sul nostro server.");
      setSnackbarOpen(true);
      console.log(error);
    }
  };

  // Funzione per le etichette
  const handleAddEtichetta = () => {
    setEtichette([...etichette, ""]);
  };

  const handleRemoveEtichetta = (index) => {
    const newEtichette = [...etichette];
    newEtichette.splice(index, 1);
    setEtichette(newEtichette);
  };

  const handleEtichettaChange = (e, index) => {
    const newEtichette = [...etichette];
    newEtichette[index] = e.target.value;
    setEtichette(newEtichette);
  };

  // Funz per le tabs
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Funz per il caricamento dei file
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify({
        id, name: file.name
      }));

      const res = await axios.post("/api/caricafileazienda", formData, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });

      const { success, error, fileId } = res.data;
      if (success) {
        const allFiles = [...allAziendaFiles, { id: fileId, name: file.name, date: new Date().toISOString() }]
        setAllAziendaFiles(allFiles);
        setAziendaFiles(allFiles);
        setFileSearchTerm('');
      } else {
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      }
    } catch (err) {
      setSnackbarMessage("Errore durante il caricamento del file.");
      setSnackbarOpen(true);
    }
  };

  const handleFileDelete = async (fileId) => {
    try {
      const res = await axios.post(`/api/eliminafileazienda`, { id: fileId, aziendaId: azienda.id }, {
        headers: utils.getAuthHeaders(),
      });

      const { success, error } = res.data;
      if (success) {
        const allFiles = allAziendaFiles.filter(f => f.id != fileId);
        setAllAziendaFiles(allFiles);
        setAziendaFiles(allFiles);
        setFileSearchTerm('');
      } else {
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      }
    } catch (err) {
      setSnackbarMessage("Errore durante l'eliminazione del file.");
      setSnackbarOpen(true);
    }
  };

  const handleFileSearchChange = (event) => {
    const value = event.target.value;
    setFileSearchTerm(value);
    if (value) {
      setAziendaFiles(
        allAziendaFiles.filter((file) =>
          file.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setAziendaFiles(allAziendaFiles);
    }
  };

  const handleClearFileSearch = () => {
    setFileSearchTerm('');
    setAziendaFiles(allAziendaFiles);
  };

  // Funzione per l ordinamento
  const handleSort = (field) => {
    if (sortField === field) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true);
      setSortField(field);
    }

    const sortedFiles = [...aziendaFiles].sort((a, b) => {
      if (field === 'date') {
        if (sortAsc) return new Date(a[field]) > new Date(b[field]) ? 1 : -1;
        return new Date(a[field]) < new Date(b[field]) ? 1 : -1;
      } else {
        if (sortAsc) return a[field] > b[field] ? 1 : -1;
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setAziendaFiles(sortedFiles);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 2, mt: 2 }}>
        Dettagli Azienda
      </Typography>
      <Paper elevation={4} sx={{ borderRadius: "16px", padding: "24px", my: 1 }}>
        {isLoading ? (
          <CircularProgress disableShrink />
        ) : (
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              orientation="horizontal"
              style={{ marginBottom: '8px' }}
            >
              <Tab label="Anagrafica" />
              <Tab label="Documenti" />
            </Tabs>

            <Box sx={{ minHeight: '400px' }}>
              {tabValue === 0 && (
                <Grid container spacing={3}>
                  {/* Colonna 1 */}
                  <Grid item xs={12} sm={3}>
                    {[
                      { label: "Nome", value: "nome" },
                      { label: "Ragione Sociale", value: "ragione_sociale" },
                      { label: "Telefono", value: "telefono" },
                    ].map(({ label, value }, index) => (
                      <React.Fragment key={index}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {label}:
                        </Typography>
                        {isEditing ? (
                          <TextField
                            name={value}
                            value={editedAzienda[value] || ""}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            sx={{ borderRadius: "8px" }}
                          />
                        ) : (
                          <Typography variant="body1">{azienda[value] || "-"}</Typography>
                        )}
                        <Divider sx={{ my: 2 }} />
                      </React.Fragment>
                    ))}
                  </Grid>

                  {/* Colonna 2 */}
                  <Grid item xs={12} sm={3}>
                    {[
                      { label: "Cognome", value: "cognome" },
                      { label: "Partita IVA", value: "partita_iva" },
                      { label: "Indirizzo", value: "indirizzo" },
                    ].map(({ label, value }, index) => (
                      <React.Fragment key={index}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {label}:
                        </Typography>
                        {isEditing ? (
                          <TextField
                            name={value}
                            value={editedAzienda[value] || ""}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            sx={{ borderRadius: "8px" }}
                          />
                        ) : (
                          <Typography variant="body1">{azienda[value] || "-"}</Typography>
                        )}
                        <Divider sx={{ my: 2 }} />
                      </React.Fragment>
                    ))}
                  </Grid>

                  {/* Colonna 3 */}
                  <Grid item xs={12} sm={3}>
                    {[
                      { label: "Città", value: "citta" },
                      { label: "CAP", value: "cap" },
                      { label: "Email", value: "email" },
                    ].map(({ label, value }, index) => (
                      <React.Fragment key={index}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {label}:
                        </Typography>
                        {isEditing ? (
                          <TextField
                            name={value}
                            value={editedAzienda[value] || ""}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            sx={{ borderRadius: "8px" }}
                          />
                        ) : (
                          <Typography variant="body1">{azienda[value] || "-"}</Typography>
                        )}
                        <Divider sx={{ my: 2 }} />
                      </React.Fragment>
                    ))}
                  </Grid>

                  {/* Colonna 4 */}
                  <Grid item xs={12} sm={3}>
                    {[
                      { label: "Provincia", value: "provincia" },
                      { label: "PEC", value: "pec" },
                      { label: "Commercialista", value: "commercialista" },
                    ].map(({ label, value }, index) => (
                      <React.Fragment key={index}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                          {label}:
                        </Typography>
                        {isEditing ? (
                          <TextField
                            name={value}
                            value={editedAzienda[value] || ""}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            sx={{ borderRadius: "8px" }}
                          />
                        ) : (
                          <Typography variant="body1">{azienda[value] || "-"}</Typography>
                        )}
                        <Divider sx={{ my: 2 }} />
                      </React.Fragment>
                    ))}
                  </Grid>

                  {/* Etichette */}
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Etichetta:</Typography>
                    {isEditing ? (
                      <Grid container spacing={0}>
                        {etichette.map((etichetta, index) => (
                          <Grid item xs={12} key={index}>
                            <Box mb={2} display="flex">
                              <TextField
                                name={`etichetta-${index}`}
                                value={etichetta}
                                onChange={(e) => handleEtichettaChange(e, index)}
                                fullWidth
                                variant="outlined"
                                sx={{ borderRadius: "8px" }}
                              />
                              <Button onClick={() => handleRemoveEtichetta(index)} sx={{ ml: 1 }}>
                                <RemoveIcon />
                              </Button>
                            </Box>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button onClick={handleAddEtichetta} variant="text" sx={{ borderRadius: "8px", mb: 2 }}>
                            <AddIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography variant="body1">{etichette.join(", ") || "Nessuna etichetta"}</Typography>
                    )}
                    <Divider sx={{ my: 1, width: '100%' }} />
                  </Grid>

                  {/* Stato Pagamenti */}
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Status Cliente:</Typography>
                    <Box display="flex" mt={1}>
                      <Tooltip title="Regolare">
                        <IconButton
                          onClick={() => isEditing && setPaymentStatus('verde')}
                          sx={{ backgroundColor: paymentStatus === 'verde' ? 'green' : 'lightgray', borderRadius: '50%', marginRight: 1 }}
                        >
                          <span style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'block' }}></span>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Medio">
                        <IconButton
                          onClick={() => isEditing && setPaymentStatus('arancione')}
                          sx={{ backgroundColor: paymentStatus === 'arancione' ? 'orange' : 'lightgray', borderRadius: '50%', marginRight: 1 }}
                        >
                          <span style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'block' }}></span>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Rischioso">
                        <IconButton
                          onClick={() => isEditing && setPaymentStatus('rosso')}
                          sx={{ backgroundColor: paymentStatus === 'rosso' ? 'red' : 'lightgray', borderRadius: '50%' }}
                        >
                          <span style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'block' }}></span>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Divider sx={{ my: 1, width: '100%' }} />
                  </Grid>

                  {/* Master */}
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Utente Master:</Typography>
                    {isEditing ? (
                      <Typography variant="body1">{editedAzienda.is_master ? "Sì" : "No"}</Typography>
                    ) : (
                      <Typography variant="body1">{azienda.is_master ? "Sì" : "No"}</Typography>
                    )}
                    {azienda.master && (
                      <>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Master:</Typography>
                        <Typography variant="body1">
                          <Link href={`/op/dettaglioazienda?id=${azienda.master}`} sx={{ color: "primary.main" }}>
                            {azienda.masterName || "Master non disponibile"}
                          </Link>
                        </Typography>
                        <Divider sx={{ my: 2, width: '100%' }} />
                      </>
                    )}
                  </Grid>

                  {/* Note */}
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Note:</Typography>
                    {isEditing ? (
                      <TextField
                        name="note"
                        value={note}
                        onChange={handleNoteChange}
                        fullWidth
                        variant="outlined"
                        sx={{ borderRadius: "8px" }}
                        multiline
                        rows={4}
                      />
                    ) : (
                      <Typography variant="body1">{note || "-"}</Typography>
                    )}
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  {/* Conferma */}
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    {isEditing ? (
                      <>
                        <Button
                          onClick={() => { setIsEditing(false); setEditedAzienda(azienda); }}
                          variant="outlined"
                          color="primary"
                          sx={{ borderRadius: "8px", marginTop: "1px", marginRight: "10px" }}
                        >
                          Annulla
                        </Button>
                        <Button onClick={handleConfirmClick} variant="contained" color="primary" sx={{ borderRadius: "8px", marginTop: "1px" }}>
                          Conferma
                        </Button>
                      </>
                    ) : (
                      <Button onClick={handleEditClick} variant="contained" color="primary" sx={{ borderRadius: "8px", marginTop: "8px" }}>
                        Modifica
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}

              {tabValue === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" mb={1}>
                      <Typography variant="body1" fontWeight="bold" gutterBottom style={{ margin: '8px', color: 'gray' }}>
                        File Azienda
                      </Typography>
                      <Tooltip title="Carica Documenti">
                        <IconButton
                          component="label"
                          color="primary"
                          style={{ marginRight: "10px" }}
                        >
                          <UploadIcon />
                          <input
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                          />
                        </IconButton>
                      </Tooltip>
                      <TextField
                        value={fileSearchTerm}
                        onChange={handleFileSearchChange}
                        label="Cerca file"
                        variant="outlined"
                        style={{ borderRadius: '10px', marginLeft: 'auto' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear search"
                                onClick={handleClearFileSearch}
                                edge="end"
                              >
                                {fileSearchTerm ? <CloseIcon /> : null}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <TableContainer component={Paper} style={{ borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <Table>

                        <TableHead>
                          <TableRow style={{ backgroundColor: '#333' }}>
                            <TableCell
                              style={{ fontWeight: 'bold', cursor: 'pointer', position: 'relative', color: '#fff' }}
                              onClick={() => handleSort('name')}
                            >
                              <Tooltip title={sortField === 'name' && sortAsc ? "Ordina per Nome Decrescente" : "Ordina per Nome Crescente"} placement="top">
                                <span>
                                  Nome File {sortField === 'name' ? (sortAsc ? '▲' : '▼') : ''}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold', cursor: 'pointer', position: 'relative', color: '#fff' }}
                              onClick={() => handleSort('date')}
                            >
                              <Tooltip title={sortField === 'date' && sortAsc ? "Ordina per Data Meno Recente" : "Ordina per Data Più Recente"} placement="top">
                                <span>
                                  Data Inserimento {sortField === 'date' ? (sortAsc ? '▲' : '▼') : ''}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold', color: '#fff' }}>Azioni</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {aziendaFiles.map((file) => (
                            <TableRow key={file.id}>
                              <TableCell style={{ width: '33.33%' }}>{file.name}</TableCell>
                              <TableCell style={{ width: '33.33%' }}>{new Date(file.date).toLocaleDateString()}</TableCell>
                              <TableCell style={{ width: '33.33%' }}>
                                <Tooltip title="Visualizza">
                                  <IconButton disabled={!file.name.endsWith('.pdf')} onClick={() => utils.opOpenPdfInNewTab(file.id)} color="primary">
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Scarica">
                                  <IconButton onClick={() => utils.opDownload(file.id)} color="success">
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Elimina">
                                  <IconButton onClick={() => handleFileDelete(file.id)} color="error">
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                          {aziendaFiles.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={3} align="center">
                                Nessun file caricato.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      </Paper>
    </Container>
  );
};

export default DettagliAzienda;
