import React, { useState, useEffect } from 'react';
import {
  TextareaAutosize, IconButton, Paper, Dialog, DialogTitle, DialogContent, DialogActions,
  Button, List, ListItem, ListItemText, Checkbox, Drawer, Fab, Divider, Tooltip, Badge,
} from '@mui/material';
import {
  Close, Add, Delete, Restore, FiberManualRecord, Notes,
  ArrowUpward, ArrowDownward,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import axios from 'axios';
import utils from '../utils';

const Memo = styled(Paper)(() => ({
  width: '100%',
  maxWidth: 300,
  background: '#ffffcc',
  borderRadius: 10,
  boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)',
  padding: 10,
  margin: 15,
  position: 'relative',
}));

const MemoTitle = styled(TextareaAutosize)(() => ({
  width: 'calc(100% - 20px)',
  height: 20,
  fontFamily: 'Comic Sans MS',
  fontWeight: 'bold',
  fontSize: 16,
  padding: '5px 10px',
  border: 'none',
  resize: 'none',
  backgroundColor: 'transparent',
  outline: 'none',
}));

const MemoContent = styled('div')(() => ({
  width: '100%',
  height: 'calc(100% - 40px)',
  border: 'none',
  overflow: 'hidden',
}));

const MemoContentTextarea = styled(TextareaAutosize)(() => ({
  width: '100%',
  height: '100%',
  border: 'none',
  resize: 'none',
  fontFamily: 'Comic Sans MS',
  fontSize: 14,
  backgroundColor: 'transparent',
  padding: 10,
  outline: 'none',
}));

const AddMemoButton = styled(IconButton)(() => ({
  backgroundColor: 'transparent',
  color: '#4CAF50',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(76, 175, 80, 0.2)',
  },
}));

const TrashButton = styled(IconButton)(() => ({
  backgroundColor: 'transparent',
  color: '#f00',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
  },
}));

const MemoGrid = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gap: 16,
  padding: 16,
  overflowY: 'auto', // Aggiungi lo scroll verticale
  paddingBottom: 120, // Spazio per i pulsanti fissi
}));

const ColorSelector = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: 1,
  paddingLeft: 10,
}));

const ColorOption = styled('div')(() => ({
  width: 20,
  height: 20,
  margin: 5,
  cursor: 'pointer',
  borderRadius: '50%',
}));

// Colori dei dot
const colors = [
  '#fff0cc', // Giallo vaniglia
  '#ffd9b3', // Arancione chiaro
  '#ffcccc', // Rosso chiaro
  '#ccffcc', // Verde chiaro
  '#b3d9ff', // Blu fiordaliso chiaro
  '#d9ccff', // Lilla chiaro
  '#ccf0ff', // Azzurro ghiaccio
];

const CloseIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: -33,
  right: -15,
  fontSize: 17,
  color: '#888',
  cursor: 'pointer',
  display: 'none',
  '&:hover': {
    color: '#f00',
  },
}));

const TopControls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  top: -40, // Sposta leggermente più in alto
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'calc(100% + 20px)',
  padding: '0 10px',
  backgroundColor: 'transparent',
  zIndex: 1,
}));

const App = () => {
  const [memos, setMemos] = useState([]);
  const [deletedMemos, setDeletedMemos] = useState([]);
  const [openTrashDialog, setOpenTrashDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userId, setUserId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [selectedMemoId, setSelectedMemoId] = useState(null);
  const [selectedDotColor, setSelectedDotColor] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    const fetchMemos = async () => {
      const loadedMemos = await loadMemos();
      if (loadedMemos) {
        setMemos(loadedMemos);
      }
    };
    fetchMemos();
  }, [userId]);

  const loadUser = async () => {
    try {
      const res = await axios.get('/api/me', {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success) {
          setUserId(me.id);
        }
      }
    } catch (_) { }
  };

  const saveMemo = async (memo) => {
    try {
      const response = await axios.post('/api/memo', memo, {
        headers: utils.getAuthHeaders(),
      });
      return response.data.success;
    } catch (error) {
      console.error('Errore durante il salvataggio della memo:', error);
    }
  };

  const updateMemo = async (memo) => {
    try {
      const response = await axios.post(`/api/updatememo`, memo, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = response.data;
      if (!success) {
        console.error(error);
      }
    } catch (error) {
      console.error('Errore durante l\'aggiornamento della memo:', error);
    }
  };

  // Carica le memo filtrando quelle eliminate
  const loadMemos = async () => {
    try {
      const response = await axios.get('/api/memos', {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, memos } = response.data;
      if (success) {
        const activeMemos = memos.filter((memo) => !memo.deleted).sort((a, b) => a.order - b.order);
        const deletedMemos = memos.filter((memo) => memo.deleted);
        setMemos(activeMemos);
        setDeletedMemos(deletedMemos);
      } else {
        console.error(error);
        return [];
      }
    } catch (error) {
      console.error('Errore durante il caricamento delle memo:', error);
    }
  };

  // Funzione Per creare una Memo
  const createMemo = async () => {
    const newMemo = {
      id: Date.now(),
      title: '',
      backgroundColor: '#ffffcc',
      checkboxes: Array.from({ length: 4 }, (_, i) => ({ id: i, checked: false, text: '' })),
      colorDot: '', // Colore del dot della nota
      order: memos.length,
    };
    const savedMemo = await saveMemo(newMemo);
    if (savedMemo) {
      setMemos([...memos, newMemo]);
    }
  };

  // Funzione Checkbox nella memo
  const handleCheckboxChange = async (memoId, checkboxId) => {
    const memoSelected = memos.filter(m => m.id == memoId)[0];
    updateMemo({
      ...memoSelected,
      checkboxes: memoSelected.checkboxes.map((checkbox) =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    });
    setMemos(
      memos.map((memo) =>
        memo.id === memoId
          ? {
            ...memo,
            checkboxes: memo.checkboxes.map((checkbox) =>
              checkbox.id === checkboxId
                ? { ...checkbox, checked: !checkbox.checked }
                : checkbox
            ),
          }
          : memo
      )
    );
  };

  // Funzione Testo dopo il checkbox
  const handleCheckboxTextChange = async (memoId, checkboxId, text) => {
    const memoSelected = memos.filter(m => m.id == memoId)[0];
    updateMemo({
      ...memoSelected,
      checkboxes: memoSelected.checkboxes.map((checkbox) =>
        checkbox.id === checkboxId ? { ...checkbox, text } : checkbox
      )
    });
    setMemos(
      memos.map((memo) =>
        memo.id === memoId
          ? {
            ...memo,
            checkboxes: memo.checkboxes.map((checkbox) =>
              checkbox.id === checkboxId ? { ...checkbox, text } : checkbox
            ),
          }
          : memo
      )
    );
  };

  // Funzione Click del Dot Colore
  const handleDotClick = async (memoId, color) => {
    setMemos(
      memos.map((memo) =>
        memo.id === memoId ? { ...memo, backgroundColor: color } : memo
      )
    );

    // Aggiorna anche le memo eliminate
    setDeletedMemos(
      deletedMemos.map((memo) =>
        memo.id === memoId ? { ...memo, backgroundColor: color } : memo
      )
    );

    setSelectedDotColor(color);

    const updatedMemo = memos.find((memo) => memo.id === memoId) || deletedMemos.find((memo) => memo.id === memoId);
    if (updatedMemo) {
      await updateMemo(updatedMemo);
    }
  };

  const handleClose = async (memo) => {
    const updatedMemo = { ...memo, deleted: true };
    setMemos(memos.filter((m) => m.id !== memo.id));
    setDeletedMemos([...deletedMemos, updatedMemo]);
    await updateMemo(updatedMemo);
  };

  const restoreMemo = async (memo) => {
    const updatedMemo = { ...memo, deleted: false };
    setMemos([...memos, updatedMemo]);
    setDeletedMemos(deletedMemos.filter((m) => m.id !== memo.id));
    await updateMemo(updatedMemo);
  };

  const emptyTrash = () => {
    setOpenConfirmDialog(true);
  };

  const confirmEmptyTrash = async () => {
    const deletedMemoIds = deletedMemos.map((memo) => memo.id);
    await axios.post('/api/deletememos', { ids: deletedMemoIds }, {
      headers: utils.getAuthHeaders(),
    });
    setDeletedMemos([]);
    setOpenConfirmDialog(false);
  };

  const cancelEmptyTrash = () => {
    setOpenConfirmDialog(false);
  };

  // Gestione Apertura e Chiusura Drawer
  const openMemoDrawer = () => {
    setIsOpen(true);
  };

  const closeMemoDrawer = () => {
    setIsOpen(false);
  };

  // Gestione Posizione Memo
  const handleMoveUp = async (memoId) => {
    const index = memos.findIndex((memo) => memo.id === memoId);
    if (index > 0) {
      const newMemos = [...memos];
      [newMemos[index], newMemos[index - 1]] = [newMemos[index - 1], newMemos[index]];
      newMemos[index].order = index;
      newMemos[index - 1].order = index - 1;
      setMemos(newMemos);

      // Salva le modifiche sul server
      await updateMemo(newMemos[index]);
      await updateMemo(newMemos[index - 1]);
    }
  };

  const handleMoveDown = async (memoId) => {
    const index = memos.findIndex((memo) => memo.id === memoId);
    if (index < memos.length - 1) {
      const newMemos = [...memos];
      [newMemos[index], newMemos[index + 1]] = [newMemos[index + 1], newMemos[index]];
      newMemos[index].order = index;
      newMemos[index + 1].order = index + 1;
      setMemos(newMemos);

      // Salva le modifiche sul server
      await updateMemo(newMemos[index]);
      await updateMemo(newMemos[index + 1]);
    }
  };

  if (!userId) {
    return null;
  }

  return (
    <>
      {/* Icona Per Aprire Drawer Note Laterale */}
      <Fab
        size="small"
        onClick={openMemoDrawer}
        sx={{
          position: 'fixed',
          bottom: 120,
          right: 10,
          backgroundColor: '#f5b300', // Giallo scuro dorato per il FAB
          '&:hover': {
            backgroundColor: '#e0a800', // Colore giallo scuro dorato più scuro al passaggio del mouse
          },
        }}
      >
        <Badge
          badgeContent={memos.length}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#f08a00', // Arancione scuro
              color: '#ffffff', // Testo bianco
            },
          }}
        >
          <Notes sx={{ color: '#ffffff' }} /> {/* Colore bianco per l'icona */}
        </Badge>
      </Fab>

      {/* Drawer Note Laterale Apertura da Destra */}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeMemoDrawer}
        PaperProps={{
          sx: {
            width: isMobileView ? '100%' : 1100,
            height: '100vh',
            borderRadius: 4,
            overflow: 'hidden',
          },
        }}
      >
        {isMobileView && (
          <IconButton
            sx={{
              position: 'absolute',
              top: -3,
              right: -3,
              zIndex: 1,
              color: 'red', // Colore rosso per il pulsante X
            }}
            onClick={closeMemoDrawer}
          >
            <Close />
          </IconButton>
        )}
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#f2f2f2',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Per mettere il contenuto in cima e i pulsanti in basso
          }}
        >
          <MemoGrid>
            {memos.map((memo) => (
              <Memo
                key={memo.id}
                style={{ backgroundColor: memo.backgroundColor }}
                onMouseEnter={() => setSelectedMemoId(memo.id)}
                onMouseLeave={() => setSelectedMemoId(null)}
              >
                {/* Dot e X e Arrow a scomparsa sulla Nota */}
                <TopControls>
                  {selectedMemoId === memo.id && (
                    <>
                      <ColorSelector>
                        {colors.map((color) => (
                          <ColorOption
                            key={color}
                            style={{ backgroundColor: color }}
                            onClick={() => handleDotClick(memo.id, color)}
                          />
                        ))}
                      </ColorSelector>

                      <Tooltip title="Sposta su">
                        <IconButton
                          onClick={() => handleMoveUp(memo.id)}
                          sx={{
                            display: 'block',
                            position: 'static',
                            marginLeft: 'auto',
                            marginTop: '10px',
                          }}
                        >
                          <ArrowUpward />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Sposta giù">
                        <IconButton
                          onClick={() => handleMoveDown(memo.id)}
                          sx={{
                            display: 'block',
                            position: 'static',
                            marginLeft: 'auto',
                            marginTop: '10px',
                          }}
                        >
                          <ArrowDownward />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Elimina Memo">
                        <CloseIconButton
                          id={`close-icon-${memo.id}`}
                          onClick={() => handleClose(memo)}
                          sx={{
                            display: 'block',
                            position: 'static',
                            marginLeft: 'auto',
                            marginTop: '10px',
                          }}
                        >
                          <Close />
                        </CloseIconButton>
                      </Tooltip>
                    </>
                  )}
                </TopControls>

                {/* Corpo Memo */}
                <MemoTitle
                  value={memo.title}
                  onChange={(e) => {
                    const newTitle = e.target.value;
                    setMemos(
                      memos.map((m) =>
                        m.id === memo.id ? { ...m, title: newTitle } : m
                      )
                    );
                    updateMemo({ ...memo, title: newTitle });
                  }}
                  placeholder="Titolo"
                />

                <MemoContent>
                  {memo.checkboxes.map((checkbox) => (
                    <div key={checkbox.id} style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={checkbox.checked}
                        onChange={() => handleCheckboxChange(memo.id, checkbox.id)}
                      />
                      <MemoContentTextarea
                        value={checkbox.text}
                        onChange={(e) => handleCheckboxTextChange(memo.id, checkbox.id, e.target.value)}
                        placeholder="scrivi..."
                      />
                    </div>
                  ))}
                </MemoContent>

              </Memo>
            ))}
          </MemoGrid>

          {/* Button Add Memo e Cestino */}
          <Divider sx={{ position: 'sticky', bottom: 0 }} />

          <div
            style={{
              position: 'absolute',
              bottom: 10,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 10px',
            }}
          >
            <Tooltip title="Cestino">
              <TrashButton onClick={() => setOpenTrashDialog(true)}>
                <Delete />
              </TrashButton>
            </Tooltip>
            <Tooltip title="Aggiungi Memo">
              <AddMemoButton onClick={createMemo} sx={{ right: 25 }}>
                <Add />
              </AddMemoButton>
            </Tooltip>
          </div>
        </div>
      </Drawer>

      {/* Dialog per il cestino */}
      <Dialog open={openTrashDialog} onClose={() => setOpenTrashDialog(false)}>
        <DialogTitle style={{ textAlign: 'center' }}>
          Cestino ({deletedMemos.length})
        </DialogTitle>
        <DialogContent>
          <List>
            {deletedMemos.map((memo) => (
              <ListItem key={memo.id}>
                <IconButton
                  style={{ padding: 0, marginRight: '10px' }}
                  size="medium"
                >
                  <FiberManualRecord style={{ color: memo.backgroundColor }} />
                </IconButton>
                <ListItemText
                  primary={memo.title || 'Senza titolo'}
                />
                <IconButton onClick={() => restoreMemo(memo)}>
                  <Restore />
                </IconButton>
              </ListItem>
            ))}
          </List>
          {deletedMemos.length === 0 && <p>Il cestino è vuoto.</p>}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={() => setOpenTrashDialog(false)} color="primary">
            Chiudi
          </Button>
          <Button onClick={emptyTrash} color="primary">
            Svuota
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog di conferma per svuotare il cestino */}
      <Dialog open={openConfirmDialog} onClose={cancelEmptyTrash}>
        <DialogTitle>Conferma eliminazione</DialogTitle>
        <DialogContent>
          Sei sicuro di voler svuotare il cestino? Questa azione è irreversibile.
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEmptyTrash} color="primary">
            Annulla
          </Button>
          <Button onClick={confirmEmptyTrash} color="primary">
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default App;
