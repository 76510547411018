import React, { useEffect, useState } from "react";
import {
  Typography, Grid, Stack, CircularProgress, Paper, Divider, Box, Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,

} from "@mui/material";
import axios from "axios";
import utils from "../../utils";
import {
  ResponsiveContainer, PieChart, Pie, Cell, Tooltip, BarChart, XAxis, YAxis, Bar, Legend,
} from "recharts";

const PAGHE_COLORS = [
  "#0088a8", // Completate
  "#035974", // Da fare
  "#FFA500"  // Da controllare
];

const COLORS = [
  "#0088a8",
  "#035974",
  "#0b3347",
  "#161e2d",
  "#4f3e2b",
  "#325167",
  "#354360",
  "#36402e",
  "#118080",
  "#7b7579"
];

const PRATICHE = [
  "Assunzioni",
  "Cessazioni",
  "Proroghe",
  "Trasformazioni",
  "Infortuni",
  "Contestazioni",
  "Distacchi",
  "Distacchi Esteri",
  "Pratiche Generiche",
  "Annullamenti",
];

const urgentColors = ["#FF5733", "#5cb85c"];

const dotStyle = {
  display: 'inline-block',
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  marginRight: '5px',
  marginLeft: '10px',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
};

const Contrattualistica = () => {
  const [completedPracticheData, setCompletedPratcheData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [presenzeData, setPresenzeData] = useState([]);
  const [pagheData, setPagheData] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [durcData, setDurcData] = useState([]);
  const [edilConnectData, setEdilConnectData] = useState([]);
  const [noteInpsData, setNoteInpsData] = useState([]);
  const [prenotazioniBars, setPrenotazioniBars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [aziendeList, setAziendeList] = useState([]);
  const [openAziendeDialog, setOpenAziendeDialog] = useState(false);

  const handlePieClick = (data, index) => {
    setAziendeList(data.aziende);
    setOpenAziendeDialog(true);
  };

  useEffect(() => {
    loadCharts();
  }, []);

  const loadCharts = async () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    try {
      const res = await axios.get(`/api/ticketstats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, stats } = res.data;
      if (success) {
        setCompletedPratcheData(res.data.stats.bars);
        setChartData([
          {
            dataKey: "value",
            data: [
              { name: "Da Fare", value: res.data.stats.urgent.tot - res.data.stats.urgent.done },
              { name: "Fatte", value: res.data.stats.urgent.done },
            ],
            title: "Urgenti",
          },
          {
            dataKey: "value",
            data: PRATICHE.map(s => { return { name: s, value: res.data.stats.tot[s.toLowerCase()] } }),
            title: "Totale Da Fare",
            linkNames: PRATICHE.map(p => p.toLowerCase()),
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const res = await axios.get(`/api/presenzestats?anno=${date.getFullYear()}&mese=${date.getMonth()}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, stats, todo } = res.data;
      if (success) {
        setPresenzeData([
          { name: "Ricevute", value: stats.ricevute.length, aziende: stats.ricevute },
          { name: "In attesa", value: stats.daRicevere.length, aziende: stats.daRicevere }]);
        setPagheData([
          { name: "Completate", value: stats.completate.length, aziende: stats.completate },
          { name: "Da fare", value: stats.daCompletare.length, aziende: stats.daCompletare },
          { name: "Da controllare", value: stats.daControllare.length, aziende: stats.daControllare }]);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const res = await axios.get(`/api/entistats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, stats } = res.data;
      if (success) {
        setDurcData([{ name: "Fatte", value: stats.durc.fatte }, { name: "Da fare", value: stats.durc.daFare }]);
        setEdilConnectData([{ name: "Fatte", value: stats.edil.fatte }, { name: "Da fare", value: stats.edil.daFare }]);
        setNoteInpsData([{ name: "Fatte", value: stats.inps.fatte }, { name: "Da fare", value: stats.inps.daFare }]);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const res = await axios.get(`/api/prenotazionistats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, bars } = res.data;
      if (success) {
        setPrenotazioniBars(bars);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  // Funzione per gestire il passaggio del mouse su una fetta
  const handlePieMouseEnter = (data, index) => {
    setActiveItem(data[index]);
  };


  if (isLoading) {
    return (
      <Stack sx={{ my: 5 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  return (
    <div>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Typography variant="h4" style={{ textAlign: "center", margin: "20px 0" }}>
          Contrattualistica
        </Typography>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {chartData.map((chart) => (
            <Grid item xs={12} md={3} key={chart.title}>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    dataKey={chart.dataKey}
                    isAnimationActive={true}
                    data={chart.data}
                    cx="50%"
                    cy="50%"
                    innerRadius={20}
                    outerRadius={60}
                    paddingAngle={5}
                    cornerRadius={5}
                    fill="#8884d8"
                    label
                  >
                    {chart.data.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          chart.data[index].value === 0
                            ? "#ccc"
                            : chart.title === "Urgenti"
                              ? urgentColors[index % urgentColors.length]
                              : COLORS[index % COLORS.length]
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <Typography
                variant="subtitle1"
                color="black"
                style={{ textAlign: "center" }}
              >
                {chart.title}
              </Typography>
            </Grid>
          ))}

          <Grid item xs={12} md={6}>
            {isLoading ? (
              <Stack sx={{ margin: 2 }} alignItems="center">
                <CircularProgress disableShrink />
              </Stack>
            ) : (
              <div>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={completedPracticheData}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    {PRATICHE.map(p => (
                      <Bar
                        key={p}
                        dataKey={p.toLowerCase()}
                        stackId="a"
                        fill={COLORS[PRATICHE.indexOf(p)]}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
                <Typography
                  variant="subtitle1"
                  color="black"
                  style={{ textAlign: "center" }}
                >
                  Pratiche Completate
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Divider />

      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant="h4" style={{ textAlign: "center", margin: "20px 0" }}>
          Paghe
        </Typography>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center" gutterBottom>
              Presenze
            </Typography>
            <div style={{ height: 200 }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={presenzeData}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={20}
                      outerRadius={60}
                      paddingAngle={5}
                      cornerRadius={5}
                      fill="#8884d8"
                      label
                      onClick={handlePieClick}
                    >
                      {presenzeData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
            <Typography
              variant="body2"
              align="center"
              gutterBottom
              style={{ fontStyle: 'italic', color: 'grey' }}
            >
              <span style={{ ...dotStyle, backgroundColor: COLORS[0] }}></span>
              Ricevute
              <span style={{ ...dotStyle, backgroundColor: COLORS[1] }}></span>
              in Attesa
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center" gutterBottom>
              Paghe
            </Typography>
            <div style={{ height: 200 }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={pagheData}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={20}
                      outerRadius={60}
                      paddingAngle={5}
                      cornerRadius={5}
                      fill="#8884d8"
                      label
                      onClick={handlePieClick}
                    >
                      {pagheData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={PAGHE_COLORS[index % PAGHE_COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
            <Typography
              variant="body2"
              align="center"
              gutterBottom
              style={{ fontStyle: 'italic', color: 'grey' }}
            >
              <span style={{ ...dotStyle, backgroundColor: PAGHE_COLORS[0] }}></span>
              Completate
              <span style={{ ...dotStyle, backgroundColor: PAGHE_COLORS[1] }}></span>
              Da fare
              <span style={{ ...dotStyle, backgroundColor: PAGHE_COLORS[2] }}></span>
              Da controllare
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={openAziendeDialog}
        onClose={() => setOpenAziendeDialog(false)}
        aria-labelledby="aziende-dialog-title"
        aria-describedby="aziende-dialog-description"
      >
        <DialogTitle id="aziende-dialog-title">{"Lista Aziende"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="aziende-dialog-description">
            <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
              {aziendeList.map((azienda, index) => (
                <li key={index}>{azienda}</li>
              ))}
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAziendeDialog(false)} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>

      <Divider />

      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant="h4" style={{ textAlign: "center", margin: "20px 0" }}>
          Enti
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-around' }}>
          <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 0 } }}>
            <Typography variant="h6">DURC</Typography>
            <PieChart width={200} height={200}>
              <Pie
                data={durcData}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                paddingAngle={2}
                onMouseEnter={handlePieMouseEnter}
              >
                {durcData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    cornerRadius={5}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => [`${value}`, "Valore"]} />
              <Legend />
            </PieChart>
            {activeItem && <Typography>Valore: {activeItem.value}</Typography>}
          </Box>
          <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 0 } }}>
            <Typography variant="h6">Edil Connect</Typography>
            <PieChart width={200} height={200}>
              <Pie
                data={edilConnectData}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#82ca9d"
                dataKey="value"
                paddingAngle={2}
                onMouseEnter={handlePieMouseEnter}
              >
                {edilConnectData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    cornerRadius={5}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => [`${value}`, "Valore"]} />
              <Legend />
            </PieChart>
            {activeItem && <Typography>Valore: {activeItem.value}</Typography>}
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Note Inps</Typography>
            <PieChart width={200} height={200}>
              <Pie
                data={noteInpsData}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                paddingAngle={2}
                onMouseEnter={handlePieMouseEnter}
              >
                {noteInpsData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    cornerRadius={5}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => [`${value}`, "Valore"]} />
              <Legend />
            </PieChart>
            {activeItem && <Typography>Valore: {activeItem.value}</Typography>}
          </Box>
        </Box>
      </Paper>

      <Divider />

      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant="h4" style={{ textAlign: "center", margin: "20px 0" }}>
          Prenotazione Sala
        </Typography>
        {isLoading ? (
          <Stack sx={{ my: 3 }} alignItems="center">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={prenotazioniBars}>
              <XAxis dataKey="giorno" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey={'ore'}
                fill={'#84a0d9'}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Paper>

    </div>
  );
};

export default Contrattualistica;
