import React, { useState, useEffect } from "react";
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  ButtonBase, Typography, IconButton, Stack, Button, Container, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip,
} from "@mui/material";
import {
  ArrowBackIos, ArrowForwardIos, CheckCircleOutline, HighlightOffOutlined,
  PendingOutlined, Download, Visibility,
} from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";

const CellStyle = {
  backgroundColor: "#333",
  color: "#fff",
  textAlign: "center",
};

const centerText = {
  textAlign: "center",
};

const dataRowSX = {
  display: "table-row",
  ":hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

const CliContrattTab = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [ticketPage, setTicketPage] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(true);
  const [statusMessageTickets, setStatusMessageTickets] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [idAnnulla, setIdAnnulla] = useState("");
  const [isMaster, setIsMaster] = useState(false);
  const [error, setError] = useState('');
  const rowsPerPage = 8;

  useEffect(() => {
    loadTickets(0);
  }, []);

  const loadTickets = async (p) => {
    try {
      const res = await axios.get(`/api/ticketsforuser?page=${p}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTickets(res.data.tickets);
        setIsMaster(res.data.tickets.length && res.data.tickets[0].azienda ? true : null);
        setTicketTotal(res.data.total);
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleAnnullaPratica = (id) => {
    setIdAnnulla(id);
    setDialogOpen(true);
  };

  const confirmAnnulla = async () => {
    setStatusMessageTickets("");

    const json = {
      tipo: "annullamento",
      pratica_id: idAnnulla,
      azienda: tickets.filter(t => t.id == idAnnulla)[0].aziendaId
    };

    const payload = new FormData();
    payload.append("data", JSON.stringify(json));

    try {
      setTicketLoading(true);
      const res = await axios.post("/api/createticket", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      setTicketLoading(false);
      if (success) {
        window.location.href = "/success?backto=%2Fcli%2Fcontrattualistica";
      } else {
        setStatusMessageTickets(error);
        return;
      }
    } catch (error) {
      setTicketLoading(false);
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
    }
  };

  // Funzione per scaricare un documento dato l'ID
  const handleDownload = async (praticaId) => {
    try {
      // endpoint diverso perche il cliente non puo vedere tutti i doc come gli op
      const response = await axios.get(`/api/scaricadocop?id=${praticaId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error(error);
    }
  };

  // Funzione per aprire l'anteprima del PDF nel browser
  const handlePreview = async (praticaId) => {
    try {
      // Chiamata per ottenere il file come blob
      const response = await axios.get(`/api/scaricadocop?id=${praticaId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      // Creazione dell'oggetto Blob con il tipo MIME
      const fileBlob = new Blob([response.data], { type: 'application/pdf' });
      // Creazione dell'URL per l'anteprima
      const fileUrl = URL.createObjectURL(fileBlob);
      // Apertura in una nuova finestra del browser
      window.open(fileUrl, "_blank");
      // Non revocare immediatamente l'URL, lascia il browser gestirlo
    } catch (error) {
      console.error('Errore durante l\'anteprima PDF:', error.response || error.message);
      setError('Errore nell\'anteprima del PDF. Riprova più tardi.');
    }
  };

  const renderStatusIcon = (status, presaInCarico) => {
    return status ? (
      <CheckCircleOutline color="success" />
    ) : (
      presaInCarico ? (
        <PendingOutlined color="warning" />
      ) : (
        <HighlightOffOutlined color="error" />
      )
    );
  };

  const renderTableRow = (row) => (
    <ButtonBase key={row.id} sx={dataRowSX} component={TableRow}>
      <TableCell style={centerText}>{row.id}</TableCell>
      <TableCell style={centerText}>{row.tipo}</TableCell>
      {isMaster && <TableCell style={centerText}>{row.azienda}</TableCell>}
      <TableCell style={centerText}>{row.cognome + ' ' + row.nome}</TableCell>
      <TableCell style={centerText}>{utils.revDate(row.timestamp.substring(0, 10))}</TableCell>
      <TableCell style={centerText}>{utils.revDate(row.data_effetto.substring(0, 10))}</TableCell>
      <TableCell style={centerText}>
        {renderStatusIcon(row.completato, row.presa_in_carico_da)}
      </TableCell>
      <TableCell>
        {row.completato && row.doc_op && ( // Mostra le icone di download e visualizzazione solo se la pratica è completata e ha un doc
          <>
            <Tooltip title="Scarica">
              <IconButton
                aria-label="download"
                color="primary"
                onClick={() => handleDownload(row.id)}
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title="Visualizza">
              <IconButton
                aria-label="view"
                color="primary"
                onClick={() => handlePreview(row.id)}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCell>
      <TableCell style={centerText}>
        {row.annullabile && (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleAnnullaPratica(row.id)}
          >
            Annulla
          </Button>
        )}
        {row.stato_annullamento && (
          <Button
            variant="contained"
            color={row.stato_annullamento.toLowerCase() == 'annullata' ? "success" : "warning"}
          >
            {row.stato_annullamento}
          </Button>
        )}
      </TableCell>
    </ButtonBase>
  );

  const handleTicketPage = (forward) => {
    if (ticketPage === 0 && !forward) {
      return;
    }
    if (rowsPerPage * (ticketPage + 1) >= ticketTotal && forward) {
      return;
    }
    const newPage = ticketPage + (forward ? 1 : -1);
    setTicketPage(newPage);
    setTicketLoading(true);
    loadTickets(newPage);
  };

  return (
    <Container>
      {ticketLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Box margin="20px" sx={{ overflowX: "auto" }}>
          <Paper sx={{ borderRadius: "10px" }}>
            <TableContainer sx={{ width: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ ...CellStyle, borderTopLeftRadius: "10px" }}
                    >
                      ID Pratica
                    </TableCell>
                    <TableCell style={CellStyle}>Tipo</TableCell>
                    {isMaster && <TableCell style={CellStyle}>Azienda</TableCell>}
                    <TableCell style={CellStyle}>Lavoratore</TableCell>
                    <TableCell style={CellStyle}>Data Richiesta</TableCell>
                    <TableCell style={CellStyle}>Data Effetto</TableCell>
                    <TableCell style={CellStyle}>Completata</TableCell>
                    <TableCell style={CellStyle}>Documenti</TableCell>
                    <TableCell
                      style={{ ...CellStyle, borderTopRightRadius: "10px" }}
                    >
                      Annullamento
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tickets.map(renderTableRow)}</TableBody>
              </Table>
            </TableContainer>
            {tickets.length == 0 && <Typography textAlign='center' sx={{ mt: 3 }}>Per creare una pratica, clicca sul pulsante a sinistra corrispondente.</Typography>}
            {tickets.length == 0 && <div style={{ height: '18px' }}></div>}
          </Paper>
          <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
            <Typography
              fontSize="1.1rem"
              sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
            >
              {`${ticketPage * rowsPerPage + 1} - ${ticketPage * rowsPerPage + tickets.length
                } di ${ticketTotal}`}
            </Typography>
            <IconButton
              onClick={() => handleTicketPage(false)}
              disabled={ticketPage === 0}
            >
              <ArrowBackIos />
            </IconButton>
            <IconButton
              onClick={() => handleTicketPage(true)}
              disabled={rowsPerPage * (ticketPage + 1) >= ticketTotal}
            >
              <ArrowForwardIos />
            </IconButton>
          </Stack>
          {statusMessageTickets && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                my: 1,
                mx: 2,
                flexGrow: 1,
                backgroundColor: "#e57373",
                borderColor: "#d32f2f",
                color: "black",
              }}
              role="alert"
            >
              {statusMessageTickets}
            </Paper>
          )}
        </Box>)}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Conferma Annullamento Pratica {idAnnulla}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {tickets.filter(t => t.id == idAnnulla)[0] && tickets.filter(t => t.id == idAnnulla)[0].presa_in_carico_da ?
              `Questa pratica è stata completata o è in fase di completamento. Confermi di voler creare una pratica di Annullamento per la pratica ${idAnnulla}?` :
              `Questa pratica non è stata ancora presa in carico. Confermi di volerla annullare?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Chiudi
          </Button>
          <Button
            onClick={confirmAnnulla}
            color="primary"
            autoFocus
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default CliContrattTab;
