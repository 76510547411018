import React, { useEffect } from "react";

const Neve = () => {
  useEffect(() => {
    const createSnowflake = () => {
      const snowflake = document.createElement("div");
      snowflake.className = "snowflake";

      const size = Math.random() * 8 + 2; // Random size
      const posX = Math.random() * window.innerWidth; // Random horizontal position
      const duration = Math.random() * 5 + 3; // Random fall duration
      const delay = Math.random() * 2; // Random delay
      const opacity = Math.random() * 0.5 + 0.3; // Random opacity
      const blur = Math.random() * 2; // Random blur

      snowflake.style.width = `${size}px`;
      snowflake.style.height = `${size}px`;
      snowflake.style.left = `${posX}px`;
      snowflake.style.animation = `fall ${duration}s linear ${delay}s`;
      snowflake.style.opacity = opacity;
      snowflake.style.filter = `blur(${blur}px)`;

      document.body.appendChild(snowflake);

      setTimeout(() => {
        snowflake.remove();
      }, (duration + delay) * 1000);
    };

    const interval = setInterval(createSnowflake, 100); // Create a snowflake every 100ms
    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const snowflakes = document.querySelectorAll(".snowflake");
      snowflakes.forEach((snowflake) => {
        const rect = snowflake.getBoundingClientRect();
        if (rect.top > window.innerHeight) {
          snowflake.remove();
        }
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <style>
      {`
        .snowflake {
          position: absolute;
          top: -10px;
          background: white;
          border-radius: 50%;
          box-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
          z-index: 1000;
        }

        @keyframes fall {
          to {
            transform: translateY(100vh);
          }
        }

        @media (max-width: 768px) {
          .snowflake {
            top: -10px;
          }
        }
      `}
    </style>
  );
};

export default Neve;
