import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import utils from '../utils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success) {
          setUserId(me.id);
          setUsername(me.op ? me.nome : me.ragione_sociale);
          setRole(me.op ? 'operator' : 'client');
        }
      }
    } catch (_) { }
  }

  return (
    <AuthContext.Provider value={{ userId, username, role }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
