// Home.js
import Video from "./Video";
import ServiceCard from "./ServiceCard";
import Position from "./Position";
import Count from "./Count";

const Home = () => {
  return (
    <div>
      <Video />
      <ServiceCard />
      {/*<Count />*/}
      <Position />
    </div>
  );
};

export default Home;
