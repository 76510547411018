import React, { useState, useEffect } from 'react';
import {
  Container, TextField, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Typography,
  Box, Grid, Accordion, AccordionSummary, AccordionDetails, Paper, Tooltip, Dialog, Snackbar,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Button, useMediaQuery, useTheme
} from '@mui/material';
import {
  Add as AddIcon, Delete as DeleteIcon, Visibility as VisibilityIcon, FileDownload as DownloadIcon,
  FileUpload as UploadIcon, ExpandMore as ExpandMoreIcon, PictureAsPdf as PdfIcon, Description as DocIcon,
  InsertDriveFile as FileIcon, Image as ImageIcon, Edit as EditIcon, Search as SearchIcon
} from '@mui/icons-material';
import axios from 'axios';
import utils from '../../utils';
import { useAuth } from '../AuthContext'; // Importa il contesto di autenticazione

const Moduli = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { role } = useAuth(); // Ottieni il ruolo dell'utente dal contesto di autenticazione

  const [categories, setCategories] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [newCategory, setNewCategory] = useState({ name: '', description: '', files: [] });
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    setFilteredCategories(
      categories.filter(category =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, categories]);

  const handleCategoryChange = (field, event) => {
    setNewCategory({
      ...newCategory,
      [field]: event.target.value,
    });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const res = await axios.get('/api/moduli', {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setCategories(res.data.categories);
      } else {
        setError(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async (event) => {
    const uploadedFiles = Array.from(event.target.files);
    const formData = new FormData();
    uploadedFiles.forEach(file => {
      formData.append('files', file);
    });
    formData.append('data', JSON.stringify({ id: editCategoryId }));

    try {
      const response = await axios.post('/api/caricamodulo', formData, {
        headers: {
          ...utils.getAuthHeaders(),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.error) {
        setError(response.error);
        console.error(error);
        return;
      }
      const uploadedFileData = response.data.files;
      setNewCategory({
        ...newCategory,
        files: [...newCategory.files, ...uploadedFileData],
      });
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleFileDelete = async (id, catId) => {
    try {
      const { success, error } = (await axios.post('/api/eliminamodulo', { id, catId }, {
        headers: utils.getAuthHeaders(),
      })).data;
      if (!success) {
        setError(error);
        console.error(error);
        return;
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    }
    const newFiles = newCategory.files.filter(f => f.id != id);
    setNewCategory({
      ...newCategory,
      files: newFiles,
    });
  };

  const addCategory = async () => {
    try {
      const { success, error, category } = (await axios.post('/api/aggiungicategoriamoduli', newCategory, {
        headers: utils.getAuthHeaders(),
      })).data;
      if (!success) {
        setError(error);
        console.error(error);
        return;
      }
      setCategories([...categories, category]);
      setNewCategory({ name: '', description: '', files: [] });
      setOpenAddDialog(false);
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const deleteCategory = async (id) => {
    try {
      const { success, error } = (await axios.post(`/api/eliminacategoriamoduli`, { id }, {
        headers: utils.getAuthHeaders()
      })).data;
      if (!success) {
        setError(error);
        console.error(error);
        return;
      }
      setCategories(categories.filter(c => c.id != id));
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleDeleteCategory = (id) => {
    setCategoryToDelete(id);
    setOpenDeleteDialog(true);
  };

  const handleEditCategory = (id) => {
    setNewCategory(categories.filter(c => c.id == id)[0]);
    setEditCategoryId(id);
    setOpenEditDialog(true);
  };

  const closeDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setNewCategory({ name: '', description: '', files: [] });
  }

  const updateCategory = async () => {
    try {
      const { success, error } = (await axios.post(`/api/modificacategoriamoduli`, newCategory, {
        headers: utils.getAuthHeaders()
      })).data;
      if (!success) {
        setError(error);
        console.error(error);
        return;
      }
      setCategories(categories.map(c => {
        if (c.id == newCategory.id) {
          return newCategory;
        } else {
          return c;
        }
      }));
      setNewCategory({ name: '', description: '', files: [] });
      setEditCategoryId(null);
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <PdfIcon />;
      case 'doc':
      case 'docx':
        return <DocIcon />;
      case 'xls':
      case 'xlsx':
        return <FileIcon />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <ImageIcon />;
      default:
        return <FileIcon />;
    }
  };

  return (
    <Container maxWidth="xxl">
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <Typography variant="h4" gutterBottom style={{ margin: 0, marginRight: '16px', display: 'flex', alignItems: 'center' }}>
                Moduli
              </Typography>
              {role === 'operator' && (
                <Tooltip title="Aggiungi Categoria">
                  <IconButton onClick={() => setOpenAddDialog(true)}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
            <TextField
              label="Cerca"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchIcon style={{ marginRight: '8px' }} />
                ),
              }}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} mt={1}>
        {filteredCategories.map(category => (
          <Grid item xs={12} sm={6} md={4} key={category.id}>
            <Paper elevation={3} style={{ marginTop: '4px', padding: '12px' }}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={10}>
                  <Box>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>{category.name}</Typography>
                    <Typography variant="subtitle1" style={{ fontStyle: 'italic', color: 'gray' }}>{category.description}</Typography>
                    <Typography variant="subtitle2" style={{ color: 'gray' }}>{new Date(category.date).toLocaleDateString()}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {role === 'operator' && (
                    <>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEditCategory(category.id)}
                        style={{ marginRight: '0px' }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>

              <Accordion style={{ marginTop: '16px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Moduli</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {category.files.map(file => (
                      <Grid item xs={12} key={file.id}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={8}>
                            <ListItemText
                              primary={
                                <Box display="flex" alignItems="center">
                                  {getFileIcon(file.name)}
                                  <Typography style={{ marginLeft: '8px' }}>
                                    {file.name}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Grid>
                          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              onClick={() => role == 'operator' ? utils.opDownload(file.id) : utils.cliDownload(file.id)}
                            >
                              <DownloadIcon />
                            </IconButton>
                            {file.name.endsWith('.pdf') && (<IconButton
                              edge="end"
                              aria-label="preview"
                              onClick={() => role == 'operator' ? utils.opOpenPdfInNewTab(file.id) : utils.cliOpenPdfInNewTab(file.id)}
                            >
                              <VisibilityIcon />
                            </IconButton>)}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Aggiungi Categoria */}
      {role === 'operator' && (
        <Dialog
          open={openAddDialog}
          onClose={closeDialog}
          aria-labelledby="add-dialog-title"
          aria-describedby="add-dialog-description"
        >
          <DialogTitle id="add-dialog-title">Aggiungi Categoria</DialogTitle>
          <DialogContent>
            <TextField
              label="Nome Categoria"
              value={newCategory.name}
              onChange={(event) => handleCategoryChange('name', event)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Descrizione"
              value={newCategory.description}
              onChange={(event) => handleCategoryChange('description', event)}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Annulla
            </Button>
            <Button onClick={addCategory} color="primary" autoFocus>
              Aggiungi
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Modifica Categoria */}
      {role === 'operator' && (
        <Dialog
          open={openEditDialog}
          onClose={closeDialog}
          aria-labelledby="edit-dialog-title"
          aria-describedby="edit-dialog-description"
        >
          <DialogTitle id="edit-dialog-title">Modifica Categoria</DialogTitle>
          <DialogContent>
            <TextField
              label="Nome Categoria"
              value={newCategory.name}
              onChange={(event) => handleCategoryChange('name', event)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Descrizione"
              value={newCategory.description}
              onChange={(event) => handleCategoryChange('description', event)}
              fullWidth
              margin="normal"
            />
            <input
              accept="*"
              style={{ display: 'none' }}
              id="file-upload"
              type="file"
              multiple
              onChange={handleFileUpload}
            />
            <label htmlFor="file-upload">
              <Tooltip title="Carica Moduli">
                <IconButton component="span">
                  <UploadIcon />
                </IconButton>
              </Tooltip>
            </label>
            <List>
              {newCategory.files.map(file => (
                <ListItem key={file.id}>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        {getFileIcon(file.name)}
                        <Typography style={{ marginLeft: '8px' }}>
                          {file.name}
                        </Typography>
                      </Box>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleFileDelete(file.id, newCategory.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Annulla
            </Button>
            <Button onClick={updateCategory} color="primary" autoFocus>
              Aggiorna
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Elimina Categoria */}
      {role === 'operator' && (
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">Conferma Eliminazione</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              Sei sicuro di voler eliminare questa categoria?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Annulla
            </Button>
            <Button onClick={() => deleteCategory(categoryToDelete)} color="primary" autoFocus>
              Elimina
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
      )}
    </Container>
  );
};

export default Moduli;
