import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Checkbox, Box, CircularProgress, Paper, TextField, Link, Divider, Container, IconButton,
} from "@mui/material";
import axios from "axios";
import utils from "../../../utils";
import JSZip from "jszip";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { Delete as DeleteIcon, CheckCircle as CheckCircleIcon, HighlightOff as HighlightOffIcon, Visibility as VisibilityIcon }
  from '@mui/icons-material';

const DetInfortunio = () => {
  const [searchParams] = useSearchParams();
  const [ticket, setTicket] = useState({ fasi: {}, datiInfortunio: {} });
  const [completatoDialogOpen, setCompletatoDialogOpen] = useState(false);
  const [pagatoDialogOpen, setPagatoDialogOpen] = useState(false);
  const [ticketLoading, setTicketLoading] = useState(true);
  const [statusMessageTicket, setStatusMessageTicket] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [completatoCanChange, setCompletatoCanChange] = useState(false);
  const [pagatoCanChange, setPagatoCanChange] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [prezzo, setPrezzo] = useState(null);

  const id = searchParams.get("id");

  useEffect(() => {
    loadData();
    loadTicket();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && me.op) {
          setIsLoading(false);
          return;
        } else if (error) {
          setError(true);
          setStatusMessageTicket(error);
        }
      }
    } catch (error) {
      setError(true);
      setStatusMessageTicket(error);
    }
    setIsLoading(false);
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadTicket = async () => {
    try {
      const res = await axios.get(`/api/ticket?id=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTicket(res.data.ticket);
        setPrezzo(res.data.prezzo && (Number(res.data.prezzo) / 100).toFixed(2));
        setCompletatoCanChange(!res.data.ticket.completato);
        setPagatoCanChange(!res.data.ticket.pagato);
      } else {
        setError(true);
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessageTicket("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setTicketLoading(false);
  };

  // Funzione per renderizzare campi di testo con etichette, con supporto per ridimensionare il font su schermi mobili
  const renderTextField = (label, value, name, additionalValues = false, multiline = false, rows = 1) => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
      marginBottom={1}
    >
      <Typography variant="body1"
        sx={{
          fontSize: { xs: '12px', sm: 'inherit' }, // Imposta il font più piccolo per schermi xs (mobile)
        }}>
        {label}:
      </Typography>
      <TextField
        fullWidth
        value={value}
        onChange={(e) => handleTextChange(e, name, additionalValues)}
        variant="outlined"
        size="small"
        multiline={multiline}
        rows={rows}
      />
    </Box>
  );

  const renderDatePicker = (label, value, name, additionalValues = false) => {
    const valueAsDate = value ? dayjs(value) : null;
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        marginBottom={1}
      >
        <Typography variant="body1"
          sx={{
            fontSize: { xs: '12px', sm: 'inherit' },
          }}>
          {label}:
        </Typography>
        <DatePicker
          format="DD/MM/YYYY"
          value={valueAsDate}
          onChange={(newValue) =>
            handleDateChange(name, newValue, additionalValues)
          }
          sx={{ width: '100%' }}
          fullWidth
        /></Box>
    )
  }

  const handleDateChange = (name, newValue, additionalValues) => {
    if (!newValue) {
      if (additionalValues) {
        setTicket({
          ...ticket, datiInfortunio: {
            ...ticket.datiInfortunio, [name]: null
          }
        });
      } else {
        setTicket({
          ...ticket,
          [name]: null,
        });
      }
      return;
    }
    if (newValue.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = newValue.$D.toString().padStart(2, "0");
    const month = (newValue.$M + 1).toString().padStart(2, "0");
    const year = newValue.$y;
    const formattedDate = `${year}-${month}-${day}`;
    if (additionalValues) {
      setTicket({
        ...ticket, datiInfortunio: {
          ...ticket.datiInfortunio, [name]: formattedDate
        }
      });
    } else {
      setTicket({
        ...ticket,
        [name]: formattedDate,
      });
    }
  };

  // Funzione per renderizzare un pulsante di download con icona di anteprima
  const renderDownloadButton = (label, id) => (
    <Box display="flex" alignItems="center" sx={{ mr: "0.5rem", width: "45%" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => downloadDoc(id)}
        sx={{ mr: "0.5rem", width: "100%" }} // Assicura che il pulsante occupi tutto lo spazio disponibile
        disabled={!Boolean(id)}
      >
        {label}
      </Button>
      <IconButton
        onClick={() => previewDoc(id)}
        disabled={!Boolean(id)}
        sx={{ width: "40px", height: "40px", color: id ? 'primary.main' : 'inherit' }} // Colora l'icona se l'ID è presente
      >
        <VisibilityIcon sx={{ fontSize: 24 }} />
      </IconButton>
    </Box>
  );

  // Funzione per visualizzare un documento in anteprima
  const previewDoc = async (docId) => {
    setStatusMessageTicket("");
    try {
      const response = await axios.get(`/api/scarica?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const fileBlob = new Blob([response.data], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(fileBlob);

      window.open(fileUrl, '_blank');
    } catch (error) {
      setError(true);
      setStatusMessageTicket("File non trovato.");
    }
  };

  // Funzione per scaricare un documento dato l'ID
  const downloadDoc = async (docId) => {
    setStatusMessageTicket("");
    try {
      const response = await axios.get(`/api/scarica?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setError(true);
      setStatusMessageTicket("File non trovato.");
    }
  };

  // Funzione per renderizzare una casella di controllo (checkbox) con etichetta
  const renderCheckboxWithLabel = (label, name) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      marginBottom="0.2rem"
    >
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "1.5rem",
        }}
        variant="h6"
      >
        {label}:
      </Typography>
      <Box>
        <Checkbox
          sx={{
            width: "18px",
            height: "18px",
            marginRight: "10px"
          }}
          checked={ticket.fasi[name] || false}
          onChange={() => {
            if (!ticket.fasi[name]) {
              setTicket({ ...ticket, fasi: { ...ticket.fasi, [name]: !ticket.fasi[name] } });
            }
          }}
          disabled={ticket.completato || false} // Disabilita il checkbox se è completato
        />
      </Box>
    </Box>
  );

  // Gestione del cambiamento di stato della casella di controllo "Completato"
  const handleCompletatoCheckboxChange = (event) => {
    if (!completatoCanChange) {
      return;
    }
    if (event.target.checked) {
      setCompletatoDialogOpen(true);
    } else {
      setTicket({ ...ticket, completato: false });
    }
  };

  // Gestione del cambiamento di stato della casella di controllo "Pagato"
  const handlePagatoCheckboxChange = (event) => {
    if (!pagatoCanChange) {
      return;
    }
    if (event.target.checked) {
      setPagatoDialogOpen(true);
    } else {
      setTicket({ ...ticket, pagato: false });
    }
  };

  // Gestione del cambiamento di testo in un campo specifico
  const handleTextChange = (event, name, additionalValues) => {
    if (additionalValues) {
      setTicket({ ...ticket, datiInfortunio: { ...ticket.datiInfortunio, [name]: event.target.value } });
    } else {
      setTicket({ ...ticket, [name]: event.target.value });
    }
  };

  // Gestione della conferma dei cambiamenti
  const handleConfirmChanges = async (e) => {
    e.preventDefault();
    setStatusMessageTicket("");
    setTicketLoading(true);
    try {
      const payload = new FormData();

      let prezzoInt = prezzo;

      if (prezzoInt) {
        prezzoInt = Math.round(Number(prezzo.toString().replace(',', '.')) * 100);
        if (!prezzoInt) {
          setStatusMessageTicket("Valore non valido per il prezzo: " + prezzo);
          setTicketLoading(false);
          return;
        }
      }

      const json = { ticket, id, prezzo: prezzoInt };

      if (selectedFiles.length) {
        if (selectedFiles.length == 1) {
          payload.append("doc", selectedFiles[0].file);
          json.doc_pratica = selectedFiles[0].name;
        } else {
          const zip = new JSZip();
          // Aggiungi tutti i file al contenuto del file zip
          for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            zip.file(file.name, file.file);
          }
          // Crea un blob zip contenente tutti i file
          const zippedBlob = await zip.generateAsync({ type: 'blob' });
          payload.append("doc", zippedBlob);
          json.doc_pratica = 'documenti.zip';
        }
      }

      payload.append("data", JSON.stringify(json));

      const res = await axios.post("/api/updateticket", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });

      const { success, error } = res.data;
      if (success) {
        setError(false);
        setCompletatoCanChange(!ticket.completato);
        setPagatoCanChange(!ticket.pagato);
        setStatusMessageTicket("Pratica aggiornata con successo.");
      } else {
        setError(true);
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  // Gestione della presa in carico della pratica
  const handlePrendiInCarico = async (e) => {
    e.preventDefault();
    setStatusMessageTicket("");
    setTicketLoading(true);
    try {
      const res = await axios.post(
        `/api/lockticket`,
        { ticket, id },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        const res = await axios.get(`/api/ticket?id=${id}`, {
          headers: utils.getAuthHeaders(),
        });
        const { success, error } = res.data;
        if (success) {
          setTicket(res.data.ticket);
          setError(false);
          setStatusMessageTicket("Pratica presa in carico con successo.");
        } else {
          setError(true);
          setStatusMessageTicket(error);
        }
      } else {
        setError(true);
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  // Funzione per chiudere i dialog di Completato e Pagato
  const handleCloseDialog = () => {
    setCompletatoDialogOpen(false);
    setPagatoDialogOpen(false);
  };

  // Funzione per selezionare i file caricati
  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    const newFiles = [...selectedFiles];
    for (let i = 0; i < files.length; i++) {
      newFiles.push({ name: files[i].name, file: files[i] });
    }
    setSelectedFiles(newFiles);
  }

  const handleDeleteFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  }

  return (
    <Container maxWidth="xl">
      <LocalizationProvider dateAdapter={AdapterDayjs}>

        <Paper elevation={3} sx={{ p: 2, maxWidth: "800px", mx: "auto", mt: 2 }}>
          {isLoading ? (
            <CircularProgress disableShrink />
          ) : (
            <div>
              <Typography variant="h4" mt={3} align="center" gutterBottom mb={5}>
                Dettaglio Infortunio {id}
              </Typography>

              {ticketLoading ? (
                <CircularProgress disableShrink />
              ) : (
                <form onSubmit={ticket.opId ? handleConfirmChanges : handlePrendiInCarico}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                    marginBottom={1}
                  >
                    <Typography variant="body1">
                      Azienda: <Link href={`/op/dettaglioazienda?id=${ticket.userId}`}>
                        {ticket.userName}
                      </Link>
                    </Typography>

                    <Typography variant="body1">
                      Presa in carico da: <Link href={`/op/dettaglioazienda?id=${ticket.opId}`}>
                        {ticket.opName}
                      </Link>
                    </Typography>

                    <Typography variant="body1">
                      Compilata da: <Link href={`/op/dettaglioazienda?id=${ticket.assegnataDaId}`}>
                        {ticket.assegnataDaName}
                      </Link>
                    </Typography>

                  </Box>

                  {ticket.opId ? (<Typography />) : (<Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    flexDirection="column"
                    px={2}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      {'Prendi in Carico'}
                    </Button>
                  </Box>)}

                  <Divider sx={{ my: 2 }} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    width="100%"
                    marginBottom={2}
                  >
                    {/* Colonna Sinistra */}
                    <div style={{ width: '48%' }}>
                      {renderTextField("Nome", ticket.nome, "nome")}
                      {renderTextField("Data di Nascita", ticket.dataNascita, "dataNascita")}
                      {renderDatePicker("Inizio Infortunio", ticket.dataInizio, "dataInizio")}

                      {renderDatePicker("Data Infortunio", ticket.datiInfortunio.dataInfortunio, "dataInfortunio", true)}
                      {renderTextField("Turno Lavorativo", ticket.datiInfortunio.turnoLavorativo, "turnoLavorativo", true)}
                      {renderTextField("Se Sì, a che Ora", ticket.datiInfortunio.seSiAcheOra, "seSiAcheOra", true)}
                      {renderTextField("Indirizzo", ticket.datiInfortunio.indirizzo, "indirizzo", true)}
                      {renderTextField("CAP", ticket.datiInfortunio.cap, "cap", true)}
                      {renderDatePicker("Saputo del Fatto Giorno", ticket.datiInfortunio.saputoDelFattoGiorno, "saputoDelFattoGiorno", true)}
                      {renderTextField("Descrizione Infortunio", ticket.datiInfortunio.descrizioneInfortunio, "descrizioneInfortunio", true, true, 7)}
                    </div>

                    <Divider orientation="vertical" flexItem />

                    {/* Colonna Destra */}
                    <div style={{ width: '48%' }}>
                      {renderTextField("Cognome", ticket.cognome, "cognome")}
                      {renderTextField("Codice Fiscale", ticket.codiceFiscale, "codiceFiscale")}
                      {renderDatePicker("Fine Infortunio", ticket.dataFine, "dataFine")}

                      {renderTextField("Ora", ticket.datiInfortunio.ora, "ora", true)}
                      {renderTextField("Ha Abbandonato il Lavoro", ticket.datiInfortunio.abbandonatoLavoro, "abbandonatoLavoro", true)}
                      {renderTextField("Luogo Infortunio", ticket.datiInfortunio.luogoInfortunio, "luogoInfortunio", true)}
                      {renderTextField("Città", ticket.datiInfortunio.citta, "citta", true)}
                      {renderTextField("Datore di Lavoro Presente", ticket.datiInfortunio.datoreLavoroPresente, "datoreLavoroPresente", true)}
                      {renderDatePicker("Ricevuto Certificato Giorno", ticket.datiInfortunio.ricevutoCertificatoGiorno, "ricevutoCertificatoGiorno", true)}
                      {renderTextField("Note", ticket.note, "note", false, true, 7)}
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="100%"
                    marginBottom={2}
                  >
                    <Typography variant="h5" sx={{ marginBottom: "1rem" }}>Scarica Documenti</Typography>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      width="100%"
                      marginBottom={1}
                    >
                      {renderDownloadButton("Carta ID", ticket.idCarta)}
                      {renderDownloadButton("Cod Fiscale", ticket.idCf)}
                    </Box>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      width="100%"
                      marginBottom={1}
                    >
                      {renderDownloadButton("P. Soggiorno", ticket.idPermesso)}
                      {renderDownloadButton("C. Residenza", ticket.idResidenza)}
                    </Box>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      width="100%"
                    >
                      {renderDownloadButton("Unilav", ticket.idUl)}
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="100%"
                    marginBottom={2}
                  >
                    <Typography variant="h5" sx={{ marginBottom: "1rem" }}>Scarica Moduli</Typography>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      width="100%"
                      marginBottom={1}
                    >
                      {renderDownloadButton("Infortunio", ticket.idInfortunio)}
                      {renderDownloadButton("Presenze", ticket.idPresenze)}
                    </Box>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      width="100%"
                      marginBottom={1}
                    >
                      {renderDownloadButton("Certificato P Soccorso", ticket.idPs)}
                      {renderDownloadButton("Certificato Medico", ticket.idMalattia)}
                    </Box>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      width="100%"
                    >
                      {renderDownloadButton("Verbale Polizia", ticket.idVerbale)}
                    </Box>
                  </Box>


                  <h2>Fasi Completamento Pratica</h2>
                  <div></div>

                  <Box>
                    {renderCheckboxWithLabel("Creazione Cartella", "creazioneCartella")}
                    <Divider variant="middle" />
                    {renderCheckboxWithLabel("Denuncia", "denuncia")}
                    <Divider variant="middle" />
                    {renderCheckboxWithLabel("Salvataggio", "salvataggio")}
                    <Divider variant="middle" />
                    {renderCheckboxWithLabel("Aggiornare Variazioni", "aggiornareVariazioni")}
                    <Divider variant="middle" />
                    {renderCheckboxWithLabel("Inviare Mail al Cliente", "inviareMailCliente")}

                  </Box>
                  <h2>Completata e Pagata</h2>
                  <div></div>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    marginBottom={1}
                  >
                    <Typography
                      sx={{
                        my: "auto",
                        color: "orange",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      Pratica Completata:
                    </Typography>

                    <Box>
                      <Checkbox
                        checked={ticket.completato}
                        onChange={(e) => handleCompletatoCheckboxChange(e)}
                      />
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    marginBottom={1}
                  >
                    <Typography
                      sx={{
                        my: "auto",
                        color: "blue",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      Inserita in Parcella:
                    </Typography>
                    <Box>

                      {ticket.avviso ? (
                        <CheckCircleIcon style={{ color: 'green', fontSize: 41 }} />
                      ) : (
                        <HighlightOffIcon style={{ color: 'red', fontSize: 41 }} />
                      )}
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    marginBottom={1}
                  >
                    <Typography
                      sx={{
                        my: "auto",
                        color: "green",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      Pratica Pagata:
                    </Typography>
                    <Box>

                      {ticket.pagato ? (
                        <CheckCircleIcon style={{ color: 'green', fontSize: 41 }} />
                      ) : (
                        <HighlightOffIcon style={{ color: 'red', fontSize: 41 }} />
                      )}
                    </Box>
                  </Box>

                  <h2>Carica Unilav o Altri Documenti</h2>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="left"
                    width="100%"
                    marginBottom={2}
                  >
                    <input
                      type="file"
                      name="docop"
                      onChange={(e) => handleMultiFileChange(e)}
                      accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                      multiple
                    />
                  </Box>
                  <Box
                    justifyContent="left"
                    width="100%"
                    marginBottom={2}
                  >
                    {selectedFiles.map((f, idx) =>
                      <Typography><IconButton onClick={() => handleDeleteFile(idx)}><DeleteIcon /></IconButton>{f.name}</Typography>)}
                  </Box>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="left"
                    width="100%"
                    marginBottom={1}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => downloadDoc(ticket.idDocOp)}
                      disabled={!Boolean(ticket.idDocOp)}
                    >
                      Scarica Doc
                    </Button>
                  </Box>

                  {prezzo !== null && <div>
                    <h2>Prezzo pratica</h2>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      marginBottom={1}
                    >
                      <TextField
                        value={prezzo}
                        onChange={(e) => setPrezzo(e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                  </div>}

                  {statusMessageTicket && (
                    <Paper
                      variant="outlined"
                      sx={{
                        p: 2,
                        mt: 2,
                        mb: 2,
                        backgroundColor: isError ? "#e57373" : "#81c784",
                        borderColor: isError ? "#d32f2f" : "#388e3c",
                        color: "black",
                      }}
                      role="alert"
                    >
                      {statusMessageTicket}
                    </Paper>
                  )}

                  <Box
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    flexDirection="column"
                    px={2}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{ mt: 2 }}
                      disabled={!ticket.opId}
                    >
                      {'Salva Modifiche'}
                    </Button>
                  </Box>

                  <Dialog open={completatoDialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Conferma Pratica Completata</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Confermi che la pratica è stata completata? Assicurati di aver spuntato
                        tutte le fasi eseguite prima di confermare.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog} color="primary">
                        Annulla
                      </Button>
                      <Button
                        onClick={() => {
                          setTicket({ ...ticket, completato: true });
                          handleCloseDialog();
                        }}
                        color="primary"
                        autoFocus
                      >
                        Conferma
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={pagatoDialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Conferma Pratica Pagata</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Confermi che la pratica è stata pagata?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog} color="primary">
                        Annulla
                      </Button>
                      <Button
                        onClick={() => {
                          setTicket({ ...ticket, pagato: true });
                          handleCloseDialog();
                        }}
                        color="primary"
                        autoFocus
                      >
                        Conferma
                      </Button>
                    </DialogActions>
                  </Dialog>
                </form>
              )}
            </div>
          )}
        </Paper>
      </LocalizationProvider>
    </Container>
  );
};

export default DetInfortunio;
