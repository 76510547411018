import React, { useState, useEffect } from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Pagination, Typography, IconButton,
  Container, Snackbar, CircularProgress, Tooltip,
} from '@mui/material';
import { Download, Visibility } from '@mui/icons-material';
import axios from 'axios';
import utils from '../../utils';

const AvvisiParcella = () => {

  const itemsPerPage = 8;
  const [page, setPage] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [parcelle, setParcelle] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/avvisiparcellacli?sp=true`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setParcelle(res.data.avvisi.map(a => {
          return {
            ...a,
            importo: (a.tot_a_pagare / 100).toFixed(2),
            dataDocumento: utils.revDate(a.data_documento.substring(0, 10)),
            dataScadenza: utils.revDate(new Date(new Date(a.data_documento).getTime() + 30 * 24 * 60 * 60 * 1000).toISOString()),
            dataPagamento: utils.revDate(a.data_pagamento.substring(0, 10)),
          }
        }));
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDownload = async (avvisoId) => {
    try {
      const response = await axios.get(`/api/avvisopdf?id=${avvisoId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error(error);
    }
  };

  // Funzione per aprire l'anteprima del PDF nel browser
  const handlePreview = async (avvisoId) => {
    try {
      const response = await axios.get(`/api/avvisopdf?id=${avvisoId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob", // Questo garantisce che riceviamo un blob
      });
  
      // Creazione dell'oggetto Blob con il tipo corretto
      const fileBlob = new Blob([response.data], { type: "application/pdf" });
      const fileUrl = URL.createObjectURL(fileBlob);
      // Apertura in una nuova finestra
      const newTab = window.open(fileUrl, "_blank");
      if (!newTab) {
        setError("Pop-up bloccato dal browser. Consenti i pop-up per vedere l'anteprima.");
      }
      // URL viene revocato dopo un breve timeout
      setTimeout(() => URL.revokeObjectURL(fileUrl), 10000);
    } catch (error) {
      console.error(error);
      setError("Errore nell'anteprima del PDF: " + error.message);
    }
  };
  
  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="center">
        <Paper elevation={0} style={{ padding: '20px', width: '100%', textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
            Avvisi di Parcella Pagati
          </Typography>
          {loading ? <CircularProgress sx={{ display: 'block', margin: '0 auto' }} /> :
            <TableContainer component={Paper} style={{ borderRadius: '8px', marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#333' }}>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>ID</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Data Documento</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Data Scadenza</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Data Pagamento</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Importo</TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>PDF</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parcelle.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">{row.dataDocumento}</TableCell>
                      <TableCell align="center">{row.dataScadenza}</TableCell>
                      <TableCell align="center">{row.dataPagamento}</TableCell>
                      <TableCell align="center">{row.importo}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Scarica File">
                          <IconButton
                            color="primary"
                            disabled={row.esterno && !row.doc_esterno}
                            onClick={() => handleDownload(row.id)}
                          >
                            <Download />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Visualizza Anteprima">
                          <IconButton
                            color="primary"
                            disabled={row.esterno && !row.doc_esterno}
                            onClick={() => handlePreview(row.id)}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(parcelle.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        </Paper>
      </Box>
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
      )}
    </Container>
  );
};

export default AvvisiParcella;
